import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { Socket, io } from "socket.io-client";
import { AuthContextType } from "../auth/AuthenticationContext";
import { PostProcessingConfiguration } from "../store/postProcessing/postProcessingConfig";
import { DefaultEventsMap } from "@socket.io/component-emitter";

function C3ServerUrl(server:string) {

    //the LOCAL_POSTPROCESSING variable is supposed to be used by developers pointing to local environment
    if (process.env.REACT_APP_LOCAL_POSTPROCESSING || server === 'localhost') { 
        const c3Port = 8998;
        const protocol = window.location.protocol === "https:" ? "https://" : "http://";
        const hostname = window.location.hostname !== server ? window.location.hostname : '127.0.0.1';
        return protocol + hostname + ":" + c3Port;
    }

    if(process.env.REACT_APP_C3_SERVER_URL) {
        return process.env.REACT_APP_C3_SERVER_URL;
    }

   return server;
}

export function createView(canvas: HTMLCanvasElement, container: HTMLElement, userData: AuthContextType) {
    const session = new cee.CloudSession();

    const viewer = session.addViewer(canvas, {
        highlightMode: cee.HighlightMode.SIMPLE,
    });

    if (!viewer) {
        throw new Error("No WebGL support");
    }

    // Resize to fill window
    const handleWindowResizeEvent = () => {
        viewer.resizeViewer(container.clientWidth, container.clientHeight);
    };

    window.addEventListener("resize", handleWindowResizeEvent);
    handleWindowResizeEvent();

    const view = viewer.addView();
    view.backgroundColor = new cee.Color3(1, 1, 1);

    //no blue box
    view.overlay.infoBoxVisible = userData.isEmployee ?? false;

    // Setup timer to keep the WebViz viewer up-to-date
    const myAnimationFrameCallback = (highResTimestamp: any) => {
        session.handleAnimationFrameCallback(highResTimestamp);
        requestAnimationFrame(myAnimationFrameCallback);
    };
    requestAnimationFrame(myAnimationFrameCallback);

    return view;
}

export async function createRemoteModel(authToken: string | undefined, config:PostProcessingConfiguration, errorCallback: (error: Error) => void) {

    const vizServerUrl = C3ServerUrl(config.serverBig);
    const connectionTimeOutMs = config.connectionTimeOutSeconds ? (config.connectionTimeOutSeconds * 1000) : 20000;
    const socket = io(vizServerUrl, {
        timeout: connectionTimeOutMs,
        rejectUnauthorized: false,
        transports: ['websocket'],
        upgrade: false,
        withCredentials: true,
        reconnection: false,
        auth: {
            token: authToken
        }
    });

    slidingWorkWindow(socket, config.workWindowTimeoutMinutes, errorCallback);

    handleSocketErrors(socket, errorCallback);

    return {
        remoteModel: new cee.ug.RemoteModel(socket),
        socket: socket
    };
}

function slidingWorkWindow(socket: Socket<DefaultEventsMap, DefaultEventsMap>, slidingWorkWindowMinutes:number, errorCallback: (error: Error) => void) {
    let timeoutId: NodeJS.Timeout;

    const workWindowMs = (slidingWorkWindowMinutes ? slidingWorkWindowMinutes: 10) * 60000;
    const restartTimer = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            socket.disconnect();
            const error = new Error("Connection closed due to inactivity");
            console.warn(error.message);
            errorCallback(error);
        }, workWindowMs);

        return timeoutId;
    };

    socket.onAny((eventname, _) => {
        if (eventname === 'cee_resUpdateServerVisualization' || eventname === 'cee_resPickResult') {
            timeoutId = restartTimer();
        }
    });

    socket.on("connect", () => {
        timeoutId = restartTimer();
    });

    //clear the timer when navigating away
    socket.on("disconnect", () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    });
}

function handleSocketErrors(socket:Socket<DefaultEventsMap, DefaultEventsMap>, errorCallback: (error: Error) => void) {

    socket.on("connect_error", (_error: Error) => {
        socket.disconnect();
        errorCallback(new Error("Error connecting to SimForm visualization server. If error persists please contact SimForm support."));
        console.error(_error.message);
    });

    socket.on("server_message", (message) => {
        console.error(message);
        const error = new Error(message);
        errorCallback(error);
    });
}

export function disconnectSocketAndClearModel(socket: Socket, view: cee.View) {
    socket?.disconnect();
    view.removeAllModels();
}

export async function loadPartInfo(model: cee.ug.RemoteModel, part: cee.ug.PartSettings, resultId: number): Promise<cee.ug.QueryBulkCalculationValues> {
    const query = new cee.ug.QueryBulkCalculation(model);

    return new Promise((resolve, reject) => {
        query.executePartQuery(model.currentFrameIndex,
            part.geometryIndex, part.partId, cee.ug.ResultType.SCALAR, resultId, (error, data) => {
                if (data) {
                    resolve(data[0]);
                }
                reject(error);
            });
    })
}