import { createContext, useEffect, useRef } from "react";
import JobData, { ChannelEndType, SyntheticMeshInfo, Tree } from "./job-data";
import { areSyntheticMeshesEqual, drawSyntheticNode } from "../../utils/hoops.utils";
import { Channel } from "./channel";
import { channelSelectionModeColorDefault, inletSelectionModeColor, outletSelectionModeColor } from "../uiSettings/communicatorColors";
import { useParams } from "react-router-dom";
import { SyntheticShape, SyntheticShapeCylinder, SyntheticShapeType } from "../../components/channels/models/synthetic-part";
import { createCylindricalChannelSegment } from "../../components/channels/helpers/channel-builder";
import { TreeviewItemModel } from "../../components/job/TreeviewNodeModel";


type MeshInstance = {
    nodeId: number;
    meshId: Communicator.MeshId;
    mesh: SyntheticMeshInfo | SyntheticShape
};

export type ColoredMesh = {
    mesh: SyntheticMeshInfo
    color: Communicator.Color
}

export type HoopsEntitiesContextType = {
    registerColoredMesh: (mesh: ColoredMesh) => Promise<number>,
    registerShape: (shape: SyntheticShape, color: Communicator.Color) => Promise<number | null>,
    unregisterMeshes: (meshes: SyntheticMeshInfo[]) => void,
    getNodeIdFromMesh: (mesh: SyntheticMeshInfo) => number | null,
    getMeshForNodeId: (nodeId: number) => SyntheticMeshInfo | null,
    getShapeForNodeId: (nodeId: number) => SyntheticShape | null
    removeShape: (nodeId: number) => Promise<void>
    getMeshes: () => MeshInstance[]
};

export const HoopsEntitiesContext = createContext<HoopsEntitiesContextType>({
    registerColoredMesh: async (mesh: ColoredMesh) => Promise.resolve(-1),
    registerShape: async (shape: SyntheticShape, color: Communicator.Color) => Promise.resolve(null),
    unregisterMeshes: () => null,
    getNodeIdFromMesh: () => null,
    getMeshForNodeId: () => null,
    getShapeForNodeId: () => null,
    removeShape: async () => Promise.resolve(),
    getMeshes: () => [],
});

export const useEntitiesContext = (jobContext: JobData, hwv: Communicator.WebViewer | null) => {
    const { id: projectId, jobId } = useParams();
    const viewer = useRef<Communicator.WebViewer | null>(null);    
    const instances = useRef<MeshInstance[]>([]);
    const tree = useRef<Tree>({});

    const getNodeIdFromMesh = (mesh: SyntheticMeshInfo) => {
        const savedMesh = instances.current.filter(i => (i.mesh as SyntheticMeshInfo).vertexes !== undefined).find(m => areSyntheticMeshesEqual(m.mesh as SyntheticMeshInfo, mesh));

        if (savedMesh) {
            return savedMesh.nodeId;
        }
        return null;
    };

    const getMeshForNodeId = (nodeId: number) => {
        const savedMesh = instances.current.filter(i => (i.mesh as SyntheticMeshInfo).vertexes !== undefined).find(m => m.nodeId === nodeId);

        if (savedMesh) {
            return savedMesh.mesh as SyntheticMeshInfo;
        }
        return null;
    };

    const registerColoredMesh = async (instance: ColoredMesh) => {
        const existingInstance = instances.current.filter(i => (i.mesh as SyntheticMeshInfo).vertexes !== undefined).find(i => areSyntheticMeshesEqual(i.mesh as SyntheticMeshInfo, instance.mesh));

        if (existingInstance) {
            return existingInstance.nodeId;
        }

        const { nodeId, meshId } = await drawSyntheticNode(instance.mesh.vertexes, instance.mesh.center, instance.color, viewer.current);

        instances.current.push({
            nodeId,
            meshId,
            mesh: instance.mesh
        });

        return nodeId;
    };

    const registerShape = async (shape: SyntheticShape, color: Communicator.Color) => {
        if (viewer.current) {
            if (shape.type === SyntheticShapeType.CYLINDER) {
                const { nodeId, meshId } = await createCylindricalChannelSegment(viewer.current, color, (shape as SyntheticShapeCylinder).diameter / 2, (shape as SyntheticShapeCylinder).length);
                
                if (shape.transform) {
                    await viewer.current.model.setNodeMatrix(nodeId, Communicator.Matrix.fromJson(shape.transform));
                }
                
                const transofrmMatrix = viewer.current.model.getNodeNetMatrix(nodeId);

                instances.current.push({
                    nodeId,
                    meshId,
                    mesh: shape
                });
                shape.transform = transofrmMatrix.toJson();
                return nodeId;
            }

        }
        return null;

    };

    const getShapeForNodeId = (nodeId: number) => {
        const savedMesh = instances.current.find(m => m.nodeId === nodeId);

        if (savedMesh && (savedMesh.mesh as SyntheticShape).transform !== undefined) {
            return savedMesh.mesh as SyntheticShape;
        }
        return null;
    };

    const removeShape = async (nodeId: number) => {
        if (viewer.current) {
            const meshToRemove = instances.current.find(i => i.nodeId === nodeId);

            if (meshToRemove) {
                await viewer.current.model.deleteMeshInstances([nodeId]);
                await viewer.current.model.deleteMeshes([meshToRemove.meshId]);
                instances.current = instances.current.filter(i => i.nodeId !== nodeId);
            }
        }
    };
    

    const unregisterMeshes = async (meshes: SyntheticMeshInfo[]) => {
        if (viewer.current) {
            const instancesToRemove: MeshInstance[] = instances.current
                .filter(i => (i.mesh as SyntheticMeshInfo).vertexes !== undefined)
                .filter(i => meshes.find(m => areSyntheticMeshesEqual(m, i.mesh as SyntheticMeshInfo)) !== undefined);
            const nodeIdsToRemove = instancesToRemove.map(mi => mi.nodeId);
            const meshIdsToRemove = instancesToRemove.map(mi => mi.meshId);

            await viewer.current.model.deleteMeshInstances(nodeIdsToRemove);
            await viewer.current.model.deleteMeshes(meshIdsToRemove);
            instances.current = instances.current.filter(i => instancesToRemove.includes(i) === false);
        }

    };

    const registerChannelMeshes = async (channels: Channel[]): Promise<{tree?: Tree, channels?: Channel[]}> => {
        const updatedChannels = channels.map(c => c.clone());
        const updatedTree = {...tree.current};
        let needsChannelsUpdate = false;
        let needsTreeUpdate = false;

        for (const channel of updatedChannels) {
            const faceGroupBaffles = channel.baffles.filter(Channel.isTreeviewFaceGroup);

            for (const baffle of faceGroupBaffles) {
                for (const face of baffle.config) {
                    if (Channel.isSyntheticFace(face) && getNodeIdFromMesh(face.config) === null) {
                        const nodeId = await registerColoredMesh({
                            mesh: face.config,
                            color: Communicator.Color.blue()
                        });
                        face.nodeId = nodeId;
                        face.faceIndex = 0;
                        needsChannelsUpdate = true;
                    }
                }
            }

            const syntheticParts = [...channel.bodies, ...channel.baffles].filter(Channel.isSyntheticPart).filter(p => p.nodesIds.length === 0);

            for (const sp of syntheticParts) {
                const nodeId = await registerShape(sp.config, channelSelectionModeColorDefault);

                if (nodeId) {
                    const treeItem: TreeviewItemModel = {
                        cadFileName: sp.cadFileName,
                        isSelected: false,
                        isVisible: true,
                        name: sp.name,
                        path: sp.path,
                        nodeIds: [nodeId]
                    }
                    sp.nodesIds = [nodeId];
                    updatedTree[treeItem.path] = treeItem;
                }

                needsTreeUpdate = true;
                needsChannelsUpdate = true;
            }

            const terminals = [...channel.inlets, ...channel.outlets];

            for (const terminal of terminals) {
                if (Channel.isSyntheticFace(terminal.face) && getNodeIdFromMesh(terminal.face.config) === null) {
                    const nodeId = await registerColoredMesh({
                        mesh: terminal.face.config,
                        color: terminal.type === ChannelEndType.Inlet ? inletSelectionModeColor : outletSelectionModeColor
                    });

                    terminal.face.nodeId = nodeId;
                    needsChannelsUpdate = true;
                }
                if (terminal.face.nodeId === Number.MIN_SAFE_INTEGER) {
                    const treeItem = updatedTree[terminal.path]; 
                    if (treeItem) {
                        terminal.face.nodeId = treeItem.nodeIds[0];
                        needsChannelsUpdate = true;
                    }
                }
            }
        }

        return {
            channels: needsChannelsUpdate ? updatedChannels : undefined,
            tree: needsTreeUpdate ? updatedTree : undefined
        }
    }

    const getMeshes = () => instances.current;

    useEffect(() => {
        instances.current = [];
    }, [projectId, jobId]);

    useEffect(() => {
        tree.current = jobContext.Tree;
    }, [jobContext.Tree]);

    useEffect(() => {
        if (hwv) {
            viewer.current = hwv;
        }
    }, [hwv]);

    return {
        getMeshes,
        unregisterMeshes,
        registerColoredMesh,
        registerChannelMeshes,
        getMeshForNodeId,
        getNodeIdFromMesh,
        registerShape,
        getShapeForNodeId,
        removeShape
    }
}