import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, TextField, Typography } from "@mui/material";
import { DEFAULT_CHANNEL_LENGTH, DEFAULT_CHANNEL_RADIUS, SyntheticShape, SyntheticShapeCylinder, SyntheticShapeType } from "./models/synthetic-part";
import { makeStyles } from "@mui/styles";
import theme from '../../styles/main-theme';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

type ChannelShapePropsDialogProps = {
    open: boolean;
    title: string;
    shape: SyntheticShape | undefined;
    onSave: ChannelShapePropsDlgSaveCallback;
    onClose: () => void;
}

export type ChannelShapePropsDlgSaveCallback = (segment: SyntheticShape) => void;

export default function ChannelShapePropsDialog(props: ChannelShapePropsDialogProps) {
    const [diameter, setDiameter] = useState<number>(DEFAULT_CHANNEL_RADIUS * 2);
    const [length, setLength] = useState<number>(DEFAULT_CHANNEL_LENGTH);

    useEffect(() => {
        if (props.shape) {
            setDiameter((props.shape as SyntheticShapeCylinder).diameter);
            setLength((props.shape as SyntheticShapeCylinder).length);
        } 
    }, [props.shape]);

    useEffect(() => { 
        if (!props.open) {
            setDiameter(DEFAULT_CHANNEL_RADIUS * 2);
            setLength(DEFAULT_CHANNEL_LENGTH);
        } else {
            console.log("ChannelShapePropsDialog opened");
        }
    }, [props.open]);

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        collapse: {
            width: "100%"
        },
        ellipsisSelect: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        select: {
            borderRadius: "2em",
            flex: "1",
            marginRight: "10px",
            minWidth: "0",
            width: "250px"
        },
    })();

    const { t } = useTranslation();
    
    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="xs"
    >
        <DialogTitle id="shape-props-dialog-title" sx={{ color: theme.palette.primary.dark }}>{props.title}</DialogTitle>
        <DialogContent>
            <FormGroup>
                <TextField
                    label="Diameter"
                    id="diameter"
                    type="number"
                    size="small"
                    margin="normal"
                    value={diameter}
                    onChange={(e) => setDiameter(parseFloat(e.target.value))}
                    InputProps={{
                        endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">mm</Typography></Box>
                    }}
                />
                <TextField
                    label="Length"
                    id="length"
                    type="number"
                    size="small"
                    margin="normal"
                    value={length}
                    onChange={(e) => setLength(parseFloat(e.target.value))}
                    InputProps={{
                        endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">mm</Typography></Box>
                    }}
                />
            </FormGroup>
        </DialogContent>
        <DialogActions>
                <Button id="create-segment-button-ok"
                    onClick={() => {
                        props.onSave({
                            type: SyntheticShapeType.CYLINDER,
                            diameter: diameter,
                            length: length
                        } as SyntheticShapeCylinder);
                    }} className={classes.btn}>{t("Save")}</Button>
                <Button id="create-segment-button-cancel" onClick={props.onClose} className={classes.btn}>{t("Cancel")}</Button>
            </DialogActions>
    </Dialog>
    )
}