import { CollectionKinds } from "./Collection";
import { CollectionSet } from "./CollectionSet";
import { Directives, composeDirective } from "./Directives";
import { Index } from "./Index";
import { Ratio } from "./Ratio";
import { toPrecision } from "./Utilities";

const x = 0;
const y = 1;
const z = 2;

export class Vertex {
    readonly x: number;
    readonly y: number;
    readonly z: number;
    readonly w: Ratio;

    constructor(x: number, y: number, z: number, w?: Ratio) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.w = w ?? new Ratio(1);
    }

    equals(other: Vertex) : boolean {
        return (this.x === other.x &&
                this.y === other.y &&
                this.z === other.z &&
                this.w.equals(other.w));
    }    

    toString() : string {
        return composeDirective(Directives.Vertex, `${toPrecision(this.x)} ${toPrecision(this.y)} ${toPrecision(this.z)} ${this.w}`);
    }
}

export class Vertices extends CollectionSet<Vertex> {
    constructor(values? : Set<Vertex>) {
        super(CollectionKinds.Vertices, values);
    }

    add(x: number, y: number, z: number, w?: Ratio) : Index {
        return this.insert(new Vertex(x, y, z, w))!;
    }
}

export function computeVertice(vertex: Communicator.MeshDataCopyVertex, itemMatrix: Communicator.Matrix) {
    if (itemMatrix.isIdentity())
        return new Vertex(vertex.position[x], vertex.position[y], vertex.position[z]);

    const point = itemMatrix.transform(new Communicator.Point3(vertex.position[x], vertex.position[y], vertex.position[z]));

    return new Vertex(point.x, point.y, point.z);
}