
export const DEFAULT_CHANNEL_RADIUS = 10;

export const DEFAULT_CHANNEL_LENGTH = 200;

export const DEFAULT_CHANNEL_ORIENTATION = [1, 0, 0];

export enum SyntheticShapeType {
    UNKNOWN,
    CYLINDER
}

export interface SyntheticShape {
    type: SyntheticShapeType;
    transform: number[]
}

export interface SyntheticShapeCylinder extends SyntheticShape {
    diameter: number;
    length: number;
}

export function getCenterLine(shape: SyntheticShapeCylinder): [Communicator.Point3, Communicator.Point3, Communicator.Point3] {
    const transformMatrix = Communicator.Matrix.createFromArray(shape.transform);
    const center = transformMatrix.transform(new Communicator.Point3(0, 0, 0));
    // since we don't scale, this makes matrix to keep only rotation part transformation
    transformMatrix.m[12] = 0;
    transformMatrix.m[13] = 0;
    transformMatrix.m[14] = 0;
    transformMatrix.m[15] = 1;


    const defaultOrientation = Communicator.Point3.createFromArray(DEFAULT_CHANNEL_ORIENTATION);
    const orientation = transformMatrix.transform(defaultOrientation.copy()).normalize().scale(shape.length / 2);
    const start = center.copy().subtract(orientation);
    const end = center.copy().add(orientation);

    return [start, center, end];
}

export function getUnitVectors(shape: SyntheticShape): [Communicator.Point3, Communicator.Point3, Communicator.Point3] {
    const transformMatrix = Communicator.Matrix.createFromArray(shape.transform);
    const center = transformMatrix.transform(new Communicator.Point3(0, 0, 0));
    // since we don't scale, this makes matrix to keep only rotation part transformation
    transformMatrix.m[12] = 0;
    transformMatrix.m[13] = 0;
    transformMatrix.m[14] = 0;
    transformMatrix.m[15] = 1;

    const xUnit = new Communicator.Point3(1,0,0);
    const yUnit = new Communicator.Point3(0,1,0);
    const zUnit = new Communicator.Point3(0,0,1);

    return [
        transformMatrix.transform(xUnit),
        transformMatrix.transform(yUnit),
        transformMatrix.transform(zUnit)
    ]
}

