import { Collection, CollectionKinds } from "./Collection";
import { Directives, composeDirective } from "./Directives";
import { Index } from "./Index";

///
/// @todo:
///     To confirm with TechSoft3D regarding HOOPS Communicator API.
///
///     Our premise is that faces are composed of triangles/triplets vertices,
///     thus for the OBJ formate we decompose the above face group of elements
///     whenever the face already contains 3 vertices. 
///
const faceVertexCompletionCount = 3;

export class FaceElement {
    readonly vertexIndex: Index;
    readonly textureIndex?: Index;
    readonly normalizedVertexIndex?: Index;

    constructor(vertexIndex: Index, textureIndex?: Index, normalizedVertexIndex?: Index) {
        this.vertexIndex = vertexIndex;
        this.textureIndex = textureIndex;
        this.normalizedVertexIndex = normalizedVertexIndex;
    }

    ///
    /// Faces is a triplet set of values written on a single as follows:
    ///    {vertex index}/{texture index}/{normalized vertex index}
    ///
    toString() : string {
        if (!this.vertexIndex)
           return '';

        if (!this.textureIndex && !this.normalizedVertexIndex)
            return `${this.vertexIndex}`;

        if (this.textureIndex && !this.normalizedVertexIndex)
            return `${this.vertexIndex}/${this.textureIndex}`;

        if (!this.textureIndex && this.normalizedVertexIndex)
            return `${this.vertexIndex}//${this.normalizedVertexIndex}`;

        return `${this.vertexIndex}/${this.textureIndex}/${this.normalizedVertexIndex}`;
    }
}

export class Face extends Collection<FaceElement> {
    constructor(values? : FaceElement[]) {
        super(CollectionKinds.FaceElements, values);
    }

    add(vertexIndex: Index, textureIndex?: Index, normalizedVertexIndex?: Index) : Index | undefined {
        return this.push(new FaceElement(vertexIndex, textureIndex, normalizedVertexIndex));
    }

    addIndexes(vertexIndexes?: Index[], textureIndexes?: Index[], normalizedVertexIndexes?: Index[]) : Index | undefined {
        if (!vertexIndexes || vertexIndexes.length === 0) {
            console.warn('No faces were added, since no matching vertices found.');
            return undefined;
        }

        if (textureIndexes &&
            textureIndexes.length !== vertexIndexes.length) {
            console.warn('`No faces were added, since there is a mis-matching between the number of vertices & textures.');
            return undefined;
        }

        if (normalizedVertexIndexes &&
            normalizedVertexIndexes.length !== vertexIndexes.length) {
            console.warn('No faces were added, since there is a mis-matching between the number of vertices & normalized vertices.');
            return undefined;
        }

        let lastIndex = undefined;

        for(let index = 0; index < vertexIndexes.length; ++index) {
            lastIndex = this.add(vertexIndexes[index],
                                 textureIndexes?.[index],
                                 normalizedVertexIndexes?.[index]);
        }

        return lastIndex;
    }

    toString() : string {
        return composeDirective(Directives.Face, this.join(' '));
    }
}

export class Faces extends Collection<Face> {
    constructor(values? : Face[]) {
        super(CollectionKinds.Faces, values);
    }

    add(face?: Face) : Index | undefined {
        return (Face.isEmpty(face))
               ? undefined
               : this.push(face);
    }
}

///
/// When a face has reached a given number of vertices then it's identified as being
/// complete. Therefore adding it to to the collection of faces and begin a new face.
///
export function addFaceWhenComplete(faces: Faces, face: Face): Face {
    if (face.length() === faceVertexCompletionCount) {
        faces.add(face);
        return new Face();
    }

    return face;
}