import { useTranslation } from "react-i18next";
import { SyntheticShape, SyntheticShapeCylinder } from "./models/synthetic-part";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from '../../styles/main-theme';

type ChannelShapeRemoveDialogProps = {
    open: boolean;
    shape: SyntheticShape;
    title: string,
    message: string,
    onSave: ChannelShapeRemoveDlgSaveCallback;
    onClose: () => void;
}

export type ChannelShapeRemoveDlgSaveCallback = () => void;

export default function ChannelShapeRemoveDialog(props: ChannelShapeRemoveDialogProps) {
    const { t } = useTranslation();
    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();
    
    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="xs"
    >
        <DialogTitle id="shape-props-dialog-title" sx={{ color: theme.palette.primary.dark }}>{t(props.title)}</DialogTitle>
        <DialogContent>
                <Typography>{t(props.message)}</Typography>
        </DialogContent>
        <DialogActions>
                <Button id="remove-segment-button-ok"
                    onClick={() => {
                        props.onSave();
                    }} className={classes.btn}>{t("Remove")}</Button>
                <Button id="create-segemtn-button-cancel" onClick={props.onClose} className={classes.btn}>{t("Cancel")}</Button>
            </DialogActions>
    </Dialog>
    )
}