import { createContext, PropsWithChildren, useCallback, useState } from "react";

interface AppStateContextType {
    allowUnload: (unloadAllowed: boolean) => void;
    isUnloadAllowed: boolean;
}

export const AppStateContext = createContext<AppStateContextType | undefined>(undefined);

export const AppStateContextProvider = (props: PropsWithChildren<{}>) => {

    const [isUnloadAllowed, setIsUnloadAllowed] = useState(true);
    const handler = useCallback((event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = true;
    }, []);
    
    const allowUnload = (unloadAllowed: boolean) => {
        if (unloadAllowed) {
            window.removeEventListener('beforeunload', handler, true);
            setIsUnloadAllowed(true);
        }
        else {
            window.addEventListener('beforeunload', handler, true);
            setIsUnloadAllowed(false);
        }
    }

    return <AppStateContext.Provider value={{
        allowUnload,
        isUnloadAllowed: isUnloadAllowed
    }}>{props.children}</AppStateContext.Provider>;
}