import { useCallback, useContext } from "react";
import { AppStateContext } from "../store/app/AppStateContext";
import { useTranslation } from "react-i18next";

type AnyFunction = (...args: any[]) => any;

export function useAppStateGuard() {
    const { t } = useTranslation();
    const appStateContext = useContext(AppStateContext);
    const checkAppStateWrapper = useCallback(<T extends AnyFunction>(fn: T): T => {
        if (appStateContext?.isUnloadAllowed === false) {
            return (() => window.alert(t("Please wait for the current operation to finish."))) as T;
        } else {
            return fn;
        }
    }, [appStateContext?.isUnloadAllowed]);

    return checkAppStateWrapper;
} 