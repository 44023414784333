/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import JobContext from '../../store/job/job-context';
import { ChannelBodyType } from '../../store/job/job-data';
import CoolingProperties from '../../store/job/cooling-properties';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Fab from '@mui/material/Fab';
import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChannelConfguration } from '../channels/ChannelConfigurationComponent';
import { MoldCategory } from '../../store/job/mold-categories';
import theme from '../../styles/main-theme';
import { TemperatureUnit, UiSettingsContext } from '../../store/uiSettings/UiSettingsContext';
import { convertTemperature } from '../../utils/units.utils';
import { Channel } from '../../store/job/channel';
import { HoopsVisibilityContext } from '../../store/job/hoops-visibility-context';
import { useTranslation } from 'react-i18next';
import { VisibilityMode, VisibilityStateActionType } from '../../store/job/hoops-visibility-reducer';
import { HoopsEntitiesContext } from '../../store/job/hoops-entities-context';

export interface CustomListProps {
    title: string,
    channels: Channel[],
    cooling: CoolingProperties,
    Hwv: Communicator.WebViewer,
    disabled: boolean,
    iterationStatus: number,
    onToggleInletOutletSelectionMode: (isInletOutletSelectionMode: boolean) => void
}

export default function CoolingCategoryDisplay({ title, channels, Hwv, disabled, iterationStatus, onToggleInletOutletSelectionMode }: CustomListProps) {
    const jobContext = useContext(JobContext);
    const uiSettingsContext = useContext(UiSettingsContext);
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation();
    const [isBusy, setIsBusy] = useState(false);
    const [isSelectingInletOutletBody, setIsSelectingInletOutletBody] = useState(false);
    const { updateVisibility, mode: visibilityMode} = useContext(HoopsVisibilityContext);
    const numberOfChannelContents = useMemo(() => {
        const numberOfBodies = channels.flatMap(c => c.bodies).length;
        const numberOfBaffles = channels.flatMap(c => c.baffles).length;
        const numberOfTerminals = [...channels.flatMap(c => c.inlets), ...channels.flatMap(c => c.outlets)].length;

        return numberOfBodies + numberOfBaffles + numberOfTerminals;
    }, [channels]);

    const toogleVisibility = () => {
        const newState = !isVisible;
        setIsVisible(newState);

        updateVisibility({
            type: VisibilityStateActionType.SET_CHANNEL_VISIBILITY,
            items: channels.flatMap(c => c.getVisibleItems()),
            visibility: newState
        });
    };

    useEffect(() => {
        if (jobContext.IsTreeLoaded && numberOfChannelContents && visibilityMode === VisibilityMode.REGULAR) {
            updateVisibility({
                type: VisibilityStateActionType.SET_CHANNEL_VISIBILITY,
                items: channels.flatMap(c => c.getVisibleItems()),
                visibility: isVisible
            });
        }

    }, [jobContext.IsTreeLoaded, numberOfChannelContents, isVisible, visibilityMode]);


    // useEffect(() => {
    //     if (jobContext.IsTreeLoaded) {
    //         registerChannelMeshes();
    //     }
    // }, [jobContext.IsTreeLoaded, numberOfChannelContents])

    const classes = makeStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: "1.3em"
        },
        headerItem: {
            position: "relative",
            marginLeft: "0.5em",
            marginRight: "1em",
            padding: "0em 1em 0em 1em",
            minWidth: "57px",
        },
        icon: {
            position: "relative",
            padding: "5px 15px 5px 15px",
            color: theme.palette.primary.dark,
            marginLeft: "10px"
        },
        paper: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1em 1em 1.4em 1em"
        },
        container: {
            display: "flex",
            alignContent: "center",
            flexDirection: "row",
            marginTop: "1em"
        },
        item: {
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            flexDirection: "row",
            "&:last-child": {
                marginLeft: "auto"
            }
        },
        elementsTitle: {
            fontSize: "0.95em",
            color: theme.palette.primary.dark,
            cursor: "pointer"
        }
    })();

    const handleChangeSelectionMode = (e: any, value: string) => {
        const bodyType = ChannelBodyType[value as keyof typeof ChannelBodyType];

        jobContext.setSelectionMode(bodyType);
    }

    const toggleInletOutletSelectionMode = (isInletOutletSelectionMode: boolean) => {
        onToggleInletOutletSelectionMode(isInletOutletSelectionMode);
    }

    const onTextFieldChange = (value: number) => {
        jobContext.setCoolingTemperature(convertTemperature({ value, unit: uiSettingsContext.uiSettings.temperatureUnit }, TemperatureUnit.C).value);
    }

    return (<>
        <Box id={"section-" + MoldCategory[MoldCategory.Channel]}>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ position: 'relative' }}>
                    {isBusy &&
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", width: "100%", height: "100%" }}>
                            <CircularProgress sx={{ color: theme.palette.primary.main }} />
                        </Box>
                    }
                    <Paper elevation={1} className={classes.paper}>
                        <div className={classes.header}>
                            <Typography sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }} variant="h6">{title}</Typography>
                            <div>
                                {<Fab disabled={isBusy || isSelectingInletOutletBody} id={"visibility-icon-" + MoldCategory[MoldCategory.Channel]} className={classes.icon} variant="extended" size="small"
                                    color="default" aria-label={t("Toggle Visibility")} onClick={toogleVisibility}>
                                    {isVisible ? <Visibility /> : <VisibilityOff />}
                                </Fab>}
                            </div>
                        </div>
                        <FormControl sx={{ mb: "8px" }} disabled={disabled || isBusy || isSelectingInletOutletBody}>
                            <FormLabel id="channel-body-selection-label" sx={{ color: theme.palette.primary.dark }}>{t("Selection mode")}</FormLabel>
                            <RadioGroup
                                row
                                defaultValue={ChannelBodyType[ChannelBodyType.FACE_GROUPS].toString()}
                                value={ChannelBodyType[jobContext.SelectionMode]}
                                onChange={handleChangeSelectionMode}
                                aria-labelledby="channel-body-selection-group-label"
                                name="channel-body-selection-group">
                                <Tooltip title={t("Choose this option if your CAD model has its channels represented as bodies")} placement="top">
                                    <FormControlLabel id="channel-body-selection-with-body" value={ChannelBodyType[ChannelBodyType.PARTS].toString()} control={<Radio size="small" />}
                                        label={<Typography style={{ fontSize: "1em" }}>{t("Bodies")}</Typography>} />
                                </Tooltip>
                                <Tooltip title={t("Choose this option if your CAD model has its channels carved out of the mold")} placement="top">
                                    <FormControlLabel id="channel-body-selection-without-body" value={ChannelBodyType[ChannelBodyType.FACE_GROUPS].toString()} control={<Radio size="small" />}
                                        label={<Typography style={{ fontSize: "1em" }}>{t("Faces")}</Typography>} />
                                </Tooltip>
                            </RadioGroup>
                        </FormControl>

                        <Grid container>
                            <Grid item xs={12}>
                                <ChannelConfguration
                                    disabled={disabled || isBusy}
                                    channels={channels}
                                    channelBodyType={jobContext.SelectionMode}
                                    Hwv={Hwv}
                                    onBusy={(isBusy) => setIsBusy(isBusy)}
                                    onSelectInletOutletBody={(isModeEnabled: boolean) => setIsSelectingInletOutletBody(isModeEnabled)}
                                    onToggleInletOutletSelectionMode={toggleInletOutletSelectionMode}
                                    iterationStatus={iterationStatus}
                                ></ChannelConfguration>
                            </Grid>
                            <Grid item className={classes.item} xs={12} >
                                <TextField disabled={disabled || isBusy} id={"cooling-liquid-temperature-input"} label={t("Inlet Temperature")} type='number'
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{uiSettingsContext.uiSettings.temperatureUnit}</InputAdornment>
                                    }}
                                    inputProps={{
                                        step: 0.1,
                                        min: 0,
                                        max: 1000
                                    }}
                                    onChange={(event: any) => onTextFieldChange(event.target.value === '' ? 0 : Number(event.target.value))}
                                    value={convertTemperature({ value: jobContext.Categories.Cooling.temperature, unit: TemperatureUnit.C }, uiSettingsContext.uiSettings.temperatureUnit).value}
                                    sx={{
                                        marginTop: '1em',
                                        marginBottom: "10px",
                                        width: '100%',
                                        '& .MuiInputBase-root': { borderRadius: '2em' },
                                        '& .MuiInputLabel-root': { fontSize: '1.2rem', backgroundColor: 'white' }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Box>
        </Box>
    </>
    );
}
