import { useContext, useEffect, useState } from "react";
import JobContext from "../../../store/job/job-context";
import { useTranslation } from "react-i18next";
import { Part, SyntheticPart } from "../../../store/job/job-data";
import { Channel } from "../../../store/job/channel";
import { clearPoints, doPartsIntersect, drawBoundingBox, drawLine, drawPoint, getFaceBoundingBox, getFaceMaxExtent, getUniquePoints, MeshDataCopyElementIterable } from "../../../utils/hoops.utils";
import { getCenterLine, SyntheticShapeCylinder } from "../../channels/models/synthetic-part";

type ValidationInfo = {
    section: string,
    label: string,
    isValid: boolean
}

enum PlacementValidationStatus {
    BAFFLE_NOT_INTERSECTS_WITH_ANY_BODY,
    BAFFLE_TOO_CLOSE_TO_BODY_EDGE,
    VALID
}

function findIntersectionBox(bb1: Communicator.Box, bb2: Communicator.Box): Communicator.Box | null {
    const { min: min1, max: max1 } = bb1
    const { min: min2, max: max2 } = bb2;

    const intersectionMin = new Communicator.Point3(
        Math.max(min1.x, min2.x),
        Math.max(min1.y, min2.y),
        Math.max(min1.z, min2.z)
    );

    const intersectionMax = new Communicator.Point3(
        Math.min(max1.x, max2.x),
        Math.min(max1.y, max2.y),
        Math.min(max1.z, max2.z)
    );

    // Check if the intersection is a valid bounding box
    if (intersectionMin.x <= intersectionMax.x &&
        intersectionMin.y <= intersectionMax.y &&
        intersectionMin.z <= intersectionMax.z) {
        return new Communicator.Box(intersectionMin, intersectionMax);
    } else {
        return null;
    }
}
// Function to find the longest dimension of a bounding box
function findLongestDimension(box: Communicator.Box): 'x' | 'y' | 'z' {
    const xLength = box.max.x - box.min.x;
    const yLength = box.max.y - box.min.y;
    const zLength = box.max.z - box.min.z;

    if (xLength >= yLength && xLength >= zLength) {
        return 'x';
    } else if (yLength >= xLength && yLength >= zLength) {
        return 'y';
    } else {
        return 'z';
    }
}

function findCenterline(box: Communicator.Box): [Communicator.Point3, Communicator.Point3] {
    const center = box.center();
    const longestDimension = findLongestDimension(box);

    let start: Communicator.Point3;
    let end: Communicator.Point3;

    switch (longestDimension) {
        case 'x':
            start = new Communicator.Point3(box.min.x, center.y, center.z);
            end = new Communicator.Point3(box.max.x, center.y, center.z);
            break;
        case 'y':
            start = new Communicator.Point3(center.x, box.min.y, center.z);
            end = new Communicator.Point3(center.x, box.max.y, center.z);
            break;
        case 'z':
            start = new Communicator.Point3(center.x, center.y, box.min.z);
            end = new Communicator.Point3(center.x, center.y, box.max.z);
            break;
    }

    return [start, end];
}

async function validateBafflePlacement(baffle: SyntheticPart, bodies: Part[], hwv: Communicator.WebViewer): Promise<PlacementValidationStatus> {
    const intersectedBodyParts = [];
    const config = baffle.config as SyntheticShapeCylinder

    for (const bp of bodies) {
        const doIntersect = await doPartsIntersect(baffle, bp, hwv.model);

        if (doIntersect) {
            intersectedBodyParts.push(bp);
        }
    }
    clearPoints(hwv);

    if (intersectedBodyParts.length === 0) {
        return PlacementValidationStatus.BAFFLE_NOT_INTERSECTS_WITH_ANY_BODY;
    } else {
        const [start, center, end] = getCenterLine(config);
        const bpClosestPoint = new Communicator.Point3(0, 0, 0);

        for (const ip of intersectedBodyParts) {
            if (Channel.isSyntheticPart(ip)) {
                const [ipStart, ipCenter, ipEnd] = getCenterLine(ip.config as SyntheticShapeCylinder);
                const ipClosestPoint = new Communicator.Point3(0, 0, 0);
                const ipConfig = ip.config as SyntheticShapeCylinder;
                const distToCenterline = Communicator.Util.distanceLineLine(start, end, ipStart, ipEnd, bpClosestPoint, ipClosestPoint);
                const distToStart = Communicator.Util.computePointToLineDistance(ipStart, start, end, new Communicator.Point3(0, 0, 0));
                const distToEnd = Communicator.Util.computePointToLineDistance(ipEnd, start, end, new Communicator.Point3(0, 0, 0));

                if ((distToCenterline + ipConfig.diameter / 2 > config.diameter / 2)
                    || distToStart < 1.1 * config.diameter / 2
                    || distToEnd < 1.1 * config.diameter / 2) {
                    return PlacementValidationStatus.BAFFLE_TOO_CLOSE_TO_BODY_EDGE;
                }
            } else {
                const distance = await hwv.model.computeMinimumBodyBodyDistance(baffle.nodesIds[0], ip.nodesIds[0]);
                const rayConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);
                const direction = Communicator.Point3.subtract(distance.pos2, center).normalize();
                const ray = new Communicator.Ray(center, direction);
                const rayResult = (await hwv.view.pickAllFromRay(ray, rayConfig)).filter(r => r.isFaceSelection()).filter(r => r.getNodeId() === ip.nodesIds[0]);
                const faceEntity = rayResult?.[0]?.getFaceEntity();

                if (!faceEntity) {
                    return PlacementValidationStatus.BAFFLE_TOO_CLOSE_TO_BODY_EDGE;
                }

                const closestFaceIndex = faceEntity.getCadFaceIndex();
                const props = await hwv.model.getFaceProperty(ip.nodesIds[0], closestFaceIndex);

                if (props?.type() === Communicator.SubentityProperties.FaceType.Cylinder) {
                    const cp = props as Communicator.SubentityProperties.CylinderElement;
                    const bb = await getFaceBoundingBox({ nodeId: ip.nodesIds[0], faceIndex: closestFaceIndex }, hwv);
                    const maxDimension = await getFaceMaxExtent({ nodeId: ip.nodesIds[0], faceIndex: closestFaceIndex }, hwv);
                    const ipStart = bb.center().subtract(cp.normal.copy().scale(maxDimension));
                    const ipEnd = bb.center().add(cp.normal.copy().scale(maxDimension));
                    const ipClosestPoint = new Communicator.Point3(0, 0, 0);
                    const dist = Communicator.Util.distanceLineLine(start, end, ipStart, ipEnd, bpClosestPoint, ipClosestPoint);
                    const distToStart = Communicator.Util.computePointToLineDistance(ipStart, start, end, new Communicator.Point3(0, 0, 0));
                    const distToEnd = Communicator.Util.computePointToLineDistance(ipEnd, start, end, new Communicator.Point3(0, 0, 0));

                    if ((dist + cp.radius > config.diameter / 2)
                        || distToStart < 1.1 * config.diameter / 2
                        || distToEnd < 1.1 * config.diameter / 2) {
                        return PlacementValidationStatus.BAFFLE_TOO_CLOSE_TO_BODY_EDGE;
                    }
                }
            }

            return PlacementValidationStatus.VALID;
        }
    }



    return PlacementValidationStatus.VALID
}

export function useJobValidation(hwv: Communicator.WebViewer | null) {
    const [validationInfo, setValidationInfo] = useState<ValidationInfo[]>([]);
    const jobContext = useContext(JobContext);
    const { t } = useTranslation();

    useEffect(() => {
        async function validate(channels: Channel[], hwv: Communicator.WebViewer) {
            const validationInfoArray: ValidationInfo[] = [];

            for (const c of channels) {
                if (c.bodies.length === 0) {
                    validationInfoArray.push({ section: `${c.id}-missed-body`, label: `${c.name}: ${t("missed a body")}`, isValid: false });
                }
                if (c.inlets.length !== 1) {
                    validationInfoArray.push({ section: `${c.id}-missed-inlets`, label: `${c.name}: ${t("missed inlets")}`, isValid: false });
                }

                if (c.outlets.length === 0) {
                    validationInfoArray.push({ section: `${c.id}-missed-outlets`, label: `${c.name}: ${t("missed outlets")}`, isValid: false });
                }

                const bodyParts = c.bodies.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);
                const baffleParts = c.baffles.filter(Channel.isSyntheticPart).filter(p => p.nodesIds.length === 1);

                for (const baffle of baffleParts) {
                    const status = await validateBafflePlacement(baffle, bodyParts, hwv);

                    if (status === PlacementValidationStatus.BAFFLE_NOT_INTERSECTS_WITH_ANY_BODY) {
                        validationInfoArray.push({ section: `${c.id}-baffle-intersect-${baffle.id}`, label: `${c.name}: ${baffle.name} ${t("does not intersect with any body")}`, isValid: false });
                    }

                    if (status === PlacementValidationStatus.BAFFLE_TOO_CLOSE_TO_BODY_EDGE) {
                        validationInfoArray.push({ section: `${c.id}-baffle-too-close-${baffle.id}`, label: `${c.name}: ${baffle.name} ${t("is not placed correctly")}`, isValid: false });
                    }
                }
            }
            setValidationInfo(validationInfoArray);
        }

        if (hwv) {
            validate(jobContext.Categories.Channel, hwv);
        }

    }, [jobContext.Categories.Channel, hwv]);


    return {
        isJobValid: validationInfo.every(v => v.isValid === true),
        validationInfo
    }

}