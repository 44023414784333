import { MoldCategory, MoldMaterialCategory, MoldMaterialCategoryForSummary } from './mold-categories';
import MoldMaterial from './mold-material';
import CoolingProperties from './cooling-properties';
import { TreeviewItemModel } from '../../components/job/TreeviewNodeModel';
import CoolingFluid from './cooling-fluid';
import CadInfo from './cad-info';
import { JobDetailsInterface } from './solver-parameters';
import { Channel } from './channel';
import { ExtraJobConfig } from '../../components/job/ExtraJobConfiguration';
import { SyntheticShape } from '../../components/channels/models/synthetic-part';

export type Tree = { [id: string]: TreeviewItemModel };

export enum Status {
    None = 0,
    Submitted = 1,
    Queued = 2,
    Dispatched = 3,
    InProgress = 4,
    Completed = 5,
    Cancelled = 6,
    SolveFailed = 2147483647
}

export enum JobView {
    Setup = 0,
    Result = 1
}

export enum ChannelEndType {
    Inlet,
    Outlet
}

export enum ChannelBodyType {
    PARTS,
    FACE_GROUPS
}

type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export const StatusLabel: EnumDictionary<number, string> = {
    [Status.None]: "Not submitted",
    [Status.Submitted]: "Submitted",
    [Status.Queued]: "Queued",
    [Status.Dispatched]: "Dispatched",
    [Status.InProgress]: "In progress",
    [Status.Completed]: "Completed",
    [Status.Cancelled]: "Cancelled",
    [Status.SolveFailed]: "Solve failed",
}

export interface Job {
    userId: string,
    id: string,
    project: string,
    created: string,
    geometryFile: string,
    status: Status,
    jsonFileSerialized: string,
    userEmail: string
}

export interface JobDetailsBase {
    Project: string,
    JobId: string,
    CadInfos: Array<CadInfo>,
    Categories: CategoriesData,
    basicMold: BasicMold,
    expectedCoolingTime: number | null,
    CustomSolverSettings: string | null
}

export interface BasicMold {
    enabled: boolean,
    nodesIds: number[]
    size: MoldSize;
    center? : Coordinates;
}

export interface JobDataInfo {
    Project: string,
    ProjectType: string,
    JobId: string,
    CustomSolverSettings: string | null,
    CadInfos: Array<CadInfo>,
    Categories: CategoriesData,
    Tree: Tree,
    IsTreeLoaded: boolean,
    Status: Status,
    contextCleaned: boolean,
    basicMold: BasicMold,    
    resizeBasicMold: boolean,
    SelectionMode: ChannelBodyType,
    expectedCoolingTime?: number | null
}

export interface iterationDialogReturnData {
    id?: string,
    projectId: string,
    name: string,
    description: string,
    existingCadInfos: Array<CadInfo>,
    cadFilesToAdd: Array<File>,
    keepCurrentSettings?: boolean,
    basedOnJobId?: string,
    updateOnCadFiles: boolean,
    containerName: string,
    customSolverSettings: string | null
};

export interface updateJobInfo {
    id: string,
    projectId: string,
    name: string,
    description: string,
    existingCadInfos: Array<CadInfo>,
    cadFilesToAdd: Array<File>,
    updateOnCadFiles: boolean,
    containerName: string,
    customSolverSettings: string| null
};

export interface ImportJobResults {
    errors: ImportJobResult[] | undefined,
    warnings: ImportJobResult[] | undefined,
    partCategories: ImportJobPathCategory[] | undefined,
    imported: boolean,
    jobId: string
}

export interface ImportJobRequest {
    projectId: string;
    jobId: string;
    containerName: string;
    description: string;
    filename: string;
    jobName: string;
}

export interface ImportJobPathCategory {
    category: string;
    paths: ImportJobPath[] | undefined;
}

export interface ImportJobResult {
    message: string;
    detail: string;
}

export interface ImportJobPath {
    name: string;
    values: string[] | undefined;
}

export interface MoldSize {
    x: number,
    y: number,
    z: number
}
export interface Coordinates {
    x: number,
    y: number,
    z: number
}

export interface JobForm {
    id?: string,
    projectId: string,
    name: string,
    description: string,
    existingCadInfos: Array<CadInfo>,
    basedOnJobId?: string,
    containerName: string,
    customSolverSettings: string | null
};

export default interface JobData extends JobDataInfo {
    setProject: (project: string) => void;
    setProjectType: (projectType: string) => void;
    setCustomSolverSettings: (customSolverSettings: string) => void;
    setJobId: (jobId: string) => void;
    setSelectionMode: (mode: ChannelBodyType) => void;
    addToCategory: (category: MoldCategory.Part | MoldCategory.Mold | MoldCategory.Runner,
        items: TreeviewFace[], moldGroupId?: string, moldGroupName?: string, validationCb?: (hasError: boolean) => void) => void;
    addToChannels: (obj: Partial<Channel>[]) => void;
    addBodyToChannel: (channelId: string, item: Part | TreeviewFaceGroup, validationCb?: (hasError: boolean) => void) => boolean;
    editChannelBody: (channelId: string, bodyId: string, config: Partial<SyntheticPart>|TreeviewFace[]) => void;
    removeBodyFromChannel: (channelId: string, bodyId: string) => void;
    addChannelEnd: (channelId: string, face: Face, type: ChannelEndType) => void;
    editChannelEnd: (channelId: string, endId: string, face: Face, type: ChannelEndType) => void;
    addChannelBaffle: (channelId: string, selectedFaces: Part|TreeviewFace[]) => void;
    editChannelBaffle: (channelId: string, baffleId: string, selectedFaces: SyntheticShape|TreeviewFace[]) => void;
    removeChannelBaffle: (channleId: string, baffleId: string) => void;
    removeChannelEnd: (channelId: string, endId: string, type: ChannelEndType) => void;
    removeFromCategory: (partId: string, category?: MoldCategory, moldGroupId?: string) => void;
    removeFromChannels: (channelId: string) => void;
    setCategoryMaterial: (categoryName: string, material: MoldMaterial, moldGroupId:string, moldGroupName?: string) => void;
    setCategoryParts: (category: MoldCategory, parts: (Part | Channel)[]) => void;
    setCoolingRadius: (radius: number) => void;
    setCoolingFlow: (radius: number) => void;
    setCoolingTemperature: (radius: number) => void;
    setCoolingFluid: (fluid: CoolingFluid) => void;    
    setTree: (treeItems: { [id: string]: TreeviewItemModel }) => void;
    setModelSelection: (selected: Face[], getGroupMembersCb?: (groupMembers: number[]) => void) => void;
    setVisibility: (nodeIds: string[], visibility: boolean) => void;
    restoreContext: (project: string, jobId: string, categories: CategoriesData, cadInfos: Array<CadInfo>, status: Status, expectedCoolingTime: number | null, customSolverSettings: string | null, basicMold?: BasicMold) => void;
    setMaterialTemperature: (categoryName: string, temperature: number, moldGroupId: string) => void;
    setUserEnteredMaterialTemperature: (categoryName: MoldMaterialCategory, temperature: number, moldGroupId: string) => void;
    setIsTreeLoaded: (isTreeLoaded: boolean) => void;
    setStatus: (status: Status) => void;
    clearContext: () => void;
    resetChannelsIdAndName: (channels: Channel[]) => void,
    SetContextCleaned: (isContextCleaned: boolean) => void,
    setSelectedChannel: (channel: Channel | undefined) => void,
    setBasicMoldNodesIds: (nodesId: number[]) => void;
    setBasicMoldMaterialIndex: (materialIndex: number) => void;
    setBasicMoldEnabled: (enabled: boolean) => void;
    setResizeBasicMold: (value: boolean) => void;
    setBasicMoldSize: (size: MoldSize) => void;
    setBasicMoldCenter: (center: Coordinates) => void;
    setFrozenWallThickness: (thickness: number) => void;
    addToMoldGoup: (moldGroupId: number, moldGroupName: string ) => void;
    setMoldTemperature: (moldTemperature: number) => void,
    setExtraJobConfiguration: (data: ExtraJobConfig) => void;
}

export interface Part {
    id: string,
    name: string,
    path: string,
    nodesIds: number[],
    cadFileName: string | null
}

export interface Face {
    nodeId: number,
    faceIndex: number
}

export interface Mold extends Part {
    bodies: Part[];
}

export interface TreeviewFace extends Face {
    path: string
};

export interface ChannelEnd {
    id: string,
    name: string,
    face: Face | SyntheticFace,
    path: string,
    type: ChannelEndType
}

export interface SyntheticMeshInfo {
    center: number[],
    vertexes: number[]
}

export interface SyntheticFace extends TreeviewFace {
    config: SyntheticMeshInfo
}

export interface SyntheticPart extends Part {
    config: SyntheticShape
}

export interface TreeviewFaceGroup {
    id: string,
    name: string,
    config: (TreeviewFace|SyntheticFace)[]
}



export type MaterialsMap = { [id: string]: MoldMaterial };

export type MaterialRootMap = { [key in MoldMaterialCategory]: MoldMaterial[] };
export type MaterialRootMapForSummary = { [key in MoldMaterialCategoryForSummary]: MoldMaterial[] };


export class CategoriesData_LegacyV1 {
    Materials: MaterialsMap = {};
    Cooling: CoolingProperties = new CoolingProperties();
    Selected: TreeviewFace[] = [];
    Part: Part[] = [];
    Runner?: Part[] = [];
    Core: Part[] = [];
    Cavity: Part[] = [];
    Channel: Channel[] = [];
    SelectedChannel: Channel | undefined;
}

export class CategoriesData {
    Materials: MaterialRootMap = {Molds: [], Parts: [], BasicMold: [], Channels: []};
    Cooling: CoolingProperties = new CoolingProperties();
    Selected: TreeviewFace[] = [];
    Part: Part[] = [];
    Runner?: Part[] = [];
    Mold: Mold[] = [{id: '0', name: 'Mold Group 1', bodies: [], path:'', nodesIds: [], cadFileName: ''}];
    Channel: Channel[] = [];
    SelectedChannel: Channel | undefined;
}

export class BasicMold{
    nodesIds: number[] = [];
    enabled = false;
    size: Coordinates = { x: 0, y: 0, z: 0 };
    center?: Coordinates = { x: 0, y: 0, z: 0 };
}

export class CategoriesDataForSummary {
    materials: MaterialRootMapForSummary = {molds: [], parts: [], basicMold: [], channels: []};
    cooling: CoolingProperties = new CoolingProperties();
    selected: TreeviewFace[] = [];
    part: Part[] = [];
    runner?: Part[] = [];
    mold: Mold[] = [{id: '0', name: 'Mold Group 1', bodies: [], path:'', nodesIds: [], cadFileName: ''}];
    channel: Channel[] = [];
    selectedChannel: Channel | undefined;
}

export interface JobCreationOptions {
    freeAccountMaxJobCreation: number,
    freeAccountMaxFeasibilityJobCreation: number,
    paidAccountMaxJobCreation: number,
    paidAccountMaxFeasibilityJobCreation: number
}

export interface JobSolveInputsParameters {
    Job: JobDetailsInterface,
    Model: {
        Channels: {
            id: string,
            name: string,
            bodies: [{
                GeometricalInfo: {
                    dataUrl: string
                }
            }],
            baffles: [{
                GeometricalInfo: {
                    dataUrl: string
                }
            }],
        }[],
        Parts: {
            id: string,
            name: string,
            bodies: [{
                GeometricalInfo: {
                    dataUrl: string
                }
            }]
        }[],
        Molds: {
            id: string,
            name: string,
            materialIndex: number,
            bodies: {
                id: string,
                name: string,
                fullName: string,
                exchangeId: string,
                materialIndex: number,
                GeometricalInfo: {
                    format: string,
                    dataUrl: string
                }
            }[]
        }[],
        Cavities?: {
            id: string,
            name: string,
            fullName: string,
            exchangeId: string,
            materialIndex: number,
            GeometricalInfo: {
                format: string,
                dataUrl: string
            }
        }[],
        Cores?: {
            id: string,
            name: string,
            fullName: string,
            exchangeId: string,
            materialIndex: number,
            GeometricalInfo: {
                format: string,
                dataUrl: string
            }
        }[]
    },
    Categories: {
        Materials: {
            Cavities?: {
                name: string,
                initialTemperature: number,
                specificHeat: number,
                density: number,
                thermalConductivity: number
            }[],
            Cores?: {
                name: string,
                initialTemperature: number,
                specificHeat: number,
                density: number,
                thermalConductivity: number
            }[],
            Molds: {
                name: string,
                initialTemperature: number,
                specificHeat: number,
                density: number,
                thermalConductivity: number,
                moldGroupId: string
            }[],
            Parts: {
                name: string,
                initialTemperature: number,
                specificHeat: number,
                density: number,
                thermalConductivity: number,
                ejectionTemperature: number
            }[]
        }
    }
}

export function getParametersFormatVersion(jobParameters: JobSolveInputsParameters): number {
    if (typeof jobParameters.Job?.version === 'number') {
        return jobParameters.Job.version;
    }
    return 1;
}

export interface JobChannelsBodiesData {
    id: string;
    name: string;
    bodiesDataUrl: string[];
}
export interface jobCategoriesData {
    Categories: CategoriesData;
    frozenWallThickness: number;
}


export const intletColor = { R: 51, G: 51, B: 255 };
export const outletColor = { R: 0, G: 255, B: 0 };

export interface CadBlob {
    fileName: string,
    blob: Blob
}

export const CanJobBeCancelled = (solveStatus: number) => {
    return (solveStatus == Status.Submitted || solveStatus == Status.Dispatched || solveStatus == Status.Queued);
}

export type ViewSelection = 'setup' | 'results';

export enum SampleJobType {
    Conventional = "conventional",
    Conformal = "conformal",
    MouseShell = "mouseshell",
    ATVGrill = "atvgrill"
}



