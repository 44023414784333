import { useCallback, useContext, useEffect, useRef, useState } from "react";
import JobContext from "../../../store/job/job-context";
import { Channel } from "../../../store/job/channel";
import { BasicMold, MaterialRootMap, Mold, Part } from "../../../store/job/job-data";
import CoolingProperties from "../../../store/job/cooling-properties";
import { useUpdateOnChange } from "../../../hooks/useUpdateOnChange";
import JobService from "../../../services/JobService";

type WatchedAppState = {
    "Channels": Channel[],
    "Molds": Mold[],
    "Parts": Part[],
    "Runners": Part[] | undefined,
    "Cooling": CoolingProperties,
    "BasicMold": BasicMold,
    "Materials": MaterialRootMap
    "ExpectedCoolingTime": number|null|undefined
}

export default function useAutosave(enabled:boolean, containerName: string | undefined) {
    const jobContext = useContext(JobContext);
    const watchedAppState = useUpdateOnChange<WatchedAppState>({
        "Channels": jobContext.Categories.Channel,
        "Molds": jobContext.Categories.Mold,
        "Parts": jobContext.Categories.Part,
        "Runners": jobContext.Categories.Runner,
        "Cooling": jobContext.Categories.Cooling,
        "BasicMold": jobContext.basicMold,
        "Materials": jobContext.Categories.Materials,
        "ExpectedCoolingTime": jobContext.expectedCoolingTime
    });

    const [needsSave, setNeedsSave] = useState(false);
    const saveFn = useCallback(async () => {
        if (containerName) {
            await JobService.saveJob(jobContext, containerName);
        }
    }, [jobContext, containerName]);

    useEffect(() => {
        if (enabled) {
            setNeedsSave(true);
        }
    }, [enabled, watchedAppState]);

    useEffect(() => {
        if (needsSave) {
            setNeedsSave(false);
            saveFn();
        }
    }, [saveFn, needsSave]);
}