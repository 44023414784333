import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ChannelContextMenuOperator, ShapePlacement } from "./operators/ChannelContextMenuOperator";
import "../job/styles/ContextMenu.css";
import { SyntheticShape, SyntheticShapeCylinder } from "./models/synthetic-part";
import JobContext from "../../store/job/job-context";
import ChannelShapePropsDialog, { ChannelShapePropsDlgSaveCallback } from "./ChannelShapePropsDialog";
import useSyntheticPartManagement from "./hooks/useSyntheticPartManagement";
import { HoopsEntitiesContext } from "../../store/job/hoops-entities-context";
import ChannelShapeRemoveDialog, { ChannelShapeRemoveDlgSaveCallback } from "./ChannelShapeRemoveDialog";
import { Part, Tree } from "../../store/job/job-data";
import { Channel } from "../../store/job/channel";

interface ChannelManagementProps {
    hwv?: Communicator.WebViewer,
    ui?: Communicator.Ui.Desktop.DesktopUi,
    operatorId: Communicator.OperatorId
}


export function ShapeCrudDialogsComponent(props: ChannelManagementProps) {
    const { hwv, operatorId } = props;
    const { getShapeForNodeId } = useContext(HoopsEntitiesContext);
    const { addPartToChannel, editPart, removePart } = useSyntheticPartManagement(hwv);
    
    const [isChannelPropsDlgOpened, toggleChannelPropsDlg] = useState<boolean>(false);
    const [isChannelRemoveDlgOpened, toggleChannelRemoveDlg] = useState<boolean>(false);
    const [channelPropsDlgTitle, setChannelPropsDlgTitle] = useState<string>("Create channel geometry");
    const [removePartTitle, setRemovePartTitle] = useState<string>("Remove channel geometry");
    const [removePartMessage, setRemovePartMessage] = useState<string>("Are you sure you want to remove this channel geometry?");
    const [channelPropsOkCallback, setChannelPropsOkCallback] = useState<ChannelShapePropsDlgSaveCallback>(() => Promise.resolve());
    const [channelShapeRemoveOkCallback, setChannelShapeRemoveOkCallback] = useState<ChannelShapeRemoveDlgSaveCallback>(() => Promise.resolve());
    const [selectedShape, setSelectedShape] = useState<SyntheticShape | undefined>();

    const operator = hwv?.operatorManager.getOperator(operatorId) as ChannelContextMenuOperator;
    const viewer = useRef<Communicator.WebViewer | null>(null);    

    if (hwv && viewer.current === null) {
        viewer.current = hwv;
    }

    const addShapeCb = async (referenceItem?: Communicator.Selection.SelectionItem, channel?: Channel, placement: ShapePlacement = ShapePlacement.Body) => {
        if (!viewer.current) {
            return;
        }

        toggleChannelPropsDlg(true);
        setSelectedShape(undefined);
        setChannelPropsDlgTitle(`Create ${placement === ShapePlacement.Body ? "body" : "baffle"}`);
        setChannelPropsOkCallback(() => (segment: SyntheticShape) => {
            addPartToChannel(segment, referenceItem, channel, placement);
            toggleChannelPropsDlg(false);
        });
    }

    const editShapeCb = async (selection: Communicator.Selection.SelectionItem, placement: ShapePlacement) => {
        if (!viewer.current) {
            return
        }
        const shapeNodeId = selection.getNodeId();
        if (!shapeNodeId) {
            return;
        }
        const shape = getShapeForNodeId(shapeNodeId) as SyntheticShapeCylinder;
        
        if (!shape) {
            return;
        }

        toggleChannelPropsDlg(true);
        setChannelPropsDlgTitle(`Edit ${placement === ShapePlacement.Body ? "body" : "baffle"}`);
        setSelectedShape(shape);
        setChannelPropsOkCallback(() => (updatedShape: SyntheticShape) => {
            editPart(shapeNodeId, updatedShape);
            toggleChannelPropsDlg(false);
        })

    }

    const removeShapeCb = useCallback(async (selection: Communicator.Selection.SelectionItem, placement: ShapePlacement) => {
        if (!hwv) {
            return;
        }
        const shapeNodeId = selection.getNodeId();
        if (!shapeNodeId) {
            return;
        }
        const shape = getShapeForNodeId(shapeNodeId) as SyntheticShapeCylinder;
        
        if (!shape) {
            return;
        }

        toggleChannelRemoveDlg(true);
        setSelectedShape(shape);
        setRemovePartTitle(`Remove ${placement === ShapePlacement.Body ? "body" : "baffle"}`);
        setRemovePartMessage(`Are you sure you want to remove this ${placement === ShapePlacement.Body ? "body" : "baffle"}?`);
        setChannelShapeRemoveOkCallback(() => () => {
            removePart(shapeNodeId);
            toggleChannelRemoveDlg(false);
            setSelectedShape(undefined);
        });
    }, [hwv, getShapeForNodeId]);


    useEffect(() => {
        if (operator) {
            operator.addShapeCb = addShapeCb;
            operator.editShapeCb = editShapeCb;
            operator.removeShapeCb = removeShapeCb;
        }
    }, [operator, addShapeCb]);

    return (<>
        <ChannelShapePropsDialog
            open={isChannelPropsDlgOpened}
            shape={selectedShape}
            title={channelPropsDlgTitle}
            onClose={() => toggleChannelPropsDlg(false)}
            onSave={channelPropsOkCallback}
        />
        {selectedShape && <ChannelShapeRemoveDialog
            open={isChannelRemoveDlgOpened}
            shape={selectedShape}
            title={removePartTitle}
            message={removePartMessage}
            onClose={() => toggleChannelRemoveDlg(false)}
            onSave={channelShapeRemoveOkCallback} 
        />}
    </>);
}