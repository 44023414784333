import { PropsWithChildren, useEffect, useState } from "react";
import { HoopsColorContext, useColorContext } from "./hoops-color-context";
import { HoopsVisibilityContext, useVisibilityContext } from "./hoops-visibility-context";
import JobData, { SyntheticMeshInfo } from "./job-data";
import { HoopsEntitiesContext, useEntitiesContext } from "./hoops-entities-context";
import { Channel } from "./channel";
import { MoldCategory } from "./mold-categories";
import { resetChannelsColors, setChannelsColors } from "../../components/channels/helpers/channel-coloring";

enum ChannelChangesHandleMode {
    INIT,
    UPDATE
}

export type HoopsContextProps = {
    hwv: Communicator.WebViewer | null,
    jobContext: JobData,
    loadedChannels: Channel[]
}

export function HoopsContext(props: PropsWithChildren<HoopsContextProps>) {
    const [channelChangesHandleMode, setChannelChangesHandleMode] = useState(ChannelChangesHandleMode.INIT);
    const { jobContext, loadedChannels, hwv } = props;
    const { updateColor, getFaceOriginalColor } = useColorContext(props.jobContext, props.hwv);
    const { registerChannelMeshes, registerColoredMesh, getNodeIdFromMesh, getMeshes, getMeshForNodeId, unregisterMeshes, getShapeForNodeId, registerShape, removeShape } = useEntitiesContext(props.jobContext, props.hwv);
    const syntheticMeshes = getMeshes().filter(m => (m.mesh as SyntheticMeshInfo).center !== undefined && (m.mesh as SyntheticMeshInfo).vertexes !== undefined);
    const { state: visibilityState, updateVisibility, getVisibility, setSelectedNodes, reset, onReset } = useVisibilityContext(syntheticMeshes, props.hwv);


    useEffect(() => {
        async function initChannels(channels: Channel[]) {
            const { tree: updatedTree, channels: updatedChannels } = await registerChannelMeshes(channels);

            if (updatedTree) {
                jobContext.setTree(updatedTree);
            }

            jobContext.setCategoryParts(MoldCategory.Channel, updatedChannels ?? channels);
            setChannelChangesHandleMode(ChannelChangesHandleMode.UPDATE);
        }

        if (loadedChannels.length) {
            initChannels(loadedChannels);
        } else {
            setChannelChangesHandleMode(ChannelChangesHandleMode.UPDATE);
        }
    }, [loadedChannels]);

    useEffect(() => {
        async function updateChannels(channels: Channel[]) {
            const { tree: updatedTree, channels: updatedChannels } = await registerChannelMeshes(channels);

            if (updatedTree) {
                jobContext.setTree(updatedTree);
            }

            if (updatedChannels) {
                jobContext.setCategoryParts(MoldCategory.Channel, updatedChannels);
            }
        }

        if (channelChangesHandleMode === ChannelChangesHandleMode.UPDATE && jobContext.Categories.Channel.length) {
            updateChannels(jobContext.Categories.Channel);
        }
    }, [jobContext.Categories.Channel, channelChangesHandleMode]);

    useEffect(() => {
        if (jobContext.Categories.Channel.length && hwv) {
            setChannelsColors(jobContext.Categories.Channel, { updateColor, getFaceOriginalColor }, jobContext.Categories.SelectedChannel);
        }
    }, [jobContext.Categories.Channel, hwv, jobContext.Categories.SelectedChannel]);

    useEffect(() => {
        if (!jobContext.contextCleaned) {
            setChannelChangesHandleMode(ChannelChangesHandleMode.INIT);
            resetChannelsColors({ updateColor, getFaceOriginalColor });
        }
    }, [hwv, jobContext.contextCleaned]);

     return (
        <HoopsEntitiesContext.Provider value={{
            getNodeIdFromMesh, getMeshForNodeId, registerColoredMesh, unregisterMeshes, getShapeForNodeId, registerShape, removeShape, getMeshes
        }}>
            <HoopsVisibilityContext.Provider value={{ mode: visibilityState.mode, updateVisibility, getVisibility, setSelectedNodes, reset, onReset }}>
                <HoopsColorContext.Provider value={{ getFaceOriginalColor, updateColor }}>
                    {props.children}
                </HoopsColorContext.Provider>
            </HoopsVisibilityContext.Provider>
        </HoopsEntitiesContext.Provider>
    )
}
