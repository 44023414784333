import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ChannelContextMenuOperator, ShapePlacement } from "../operators/ChannelContextMenuOperator";
import JobContext from "../../../store/job/job-context";
import { HoopsEntitiesContext } from "../../../store/job/hoops-entities-context";
import { SyntheticShape } from "../models/synthetic-part";
import { Channel } from "../../../store/job/channel";

export default function useChannelDesign(enabled: boolean, hwv?: Communicator.WebViewer, ui?: Communicator.Ui.Desktop.DesktopUi) {
    const viewer = useRef<Communicator.WebViewer>();
    const jobContext = useContext(JobContext);
    const { getShapeForNodeId, getMeshes } = useContext(HoopsEntitiesContext);
    const [operatorId, setOperatorId] = useState<Communicator.OperatorId>(Communicator.OperatorId.None);

    const selectionCallback = useCallback(async (selectedNodes: Communicator.Event.NodeSelectionEvent[], removed: boolean) => {
        if (!viewer.current || operatorId === Communicator.OperatorId.None) {
            return;
        }
        const handleOperator = viewer.current.operatorManager.getOperator(Communicator.OperatorId.Handle) as Communicator.Operator.HandleOperator;
        const selections = viewer.current.selectionManager.getResults();
        const selectedSyntheticShapeIds = selections
            .map(s => s.getNodeId()).filter(n => getShapeForNodeId(n))
            .filter(shape => shape !== null) as number[];

        if (selectedSyntheticShapeIds.length) {
            handleOperator.addHandles(selectedSyntheticShapeIds);
        } else {
            handleOperator.removeHandles();
        }
    }, [operatorId]);

    const handleCallback = useCallback((eventType: Communicator.HandleEventType, nodeIds: number[], initialMatrices: Communicator.Matrix[], newMatrices: Communicator.Matrix[]) => {
        nodeIds.forEach((nodeId, index) => {
            const shape = getShapeForNodeId(nodeId) as SyntheticShape;
            if (!shape) {
                return;
            }

            for (const channel of jobContext.Categories.Channel) {
                const body = channel.bodies.filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId));
                
                if (body) {
                    shape.transform = newMatrices[index].toJson();
                    jobContext.editChannelBody(channel.id, body.id, {config: shape});
                    return;
                }

                const baffle = channel.baffles.filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId));
                if (baffle) {
                    shape.transform = newMatrices[index].toJson();
                    jobContext.editChannelBaffle(channel.id, baffle.id, shape);
                    return;
                }
            }
        });
    }, [jobContext]);

    useEffect(() => {
        hwv?.setCallbacks({
            selectionArray: selectionCallback,
            handleEventEnd: handleCallback
        });

        return () => {
            hwv?.unsetCallbacks({
                selectionArray: selectionCallback,
                handleEventEnd: handleCallback
            });
        }

    }, [hwv, selectionCallback, handleCallback]);

    useEffect(() => {
        if (!hwv || !ui || !enabled) {
            return;
        }

        const operator = new ChannelContextMenuOperator(hwv, ui)
        const operatorId = hwv.registerCustomOperator(operator);

        viewer.current = hwv;
        hwv.operatorManager.push(operatorId);
        setOperatorId(operatorId);

        return () => {
            if (hwv) {
                hwv.operatorManager.remove(operatorId);
                hwv.unregisterCustomOperator(operatorId);
                setOperatorId(Communicator.OperatorId.None);
            }
        }
    }, [hwv, ui, enabled]);

    useEffect(() => {
        if (viewer.current && operatorId !== Communicator.OperatorId.None) {
            const contextMenuOperator = viewer.current.operatorManager.getOperator(operatorId) as ChannelContextMenuOperator;

            contextMenuOperator.channels = jobContext.Categories.Channel;

            contextMenuOperator.getNodeInfo = (nodeId: number) => {
                const isSynthetic = getShapeForNodeId(nodeId) !== null;
                let channel: Channel|undefined = undefined;
                let placement: ShapePlacement|undefined = undefined;

                for (const c of jobContext.Categories.Channel) {
                    const body = c.bodies.filter(Channel.isPart).find(b => b.nodesIds.includes(nodeId));
                    if (body) {
                        placement = ShapePlacement.Body;
                        channel = c;
                        break;
                    }

                    const baffle = c.baffles.filter(Channel.isPart).find(b => b.nodesIds.includes(nodeId));
                    if (baffle) {
                        placement = ShapePlacement.Baffle;
                        channel = c;
                        break;
                    }
                }

                return { isSynthetic, channel, placement };
            }
        }
    }, [operatorId, jobContext.Categories.Channel]);

    return { operatorId }
}