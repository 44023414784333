import { Channel } from "../../../store/job/channel";

declare var $: JQueryStatic;

type ChannelContextMenuItem = { id: string, parent?: string, title: string, action?: () => Promise<void> };

export enum ShapePlacement {
    Body,
    Baffle
}

export type AddShapeCallback = (selection?: Communicator.Selection.SelectionItem, channel?: Channel, placement?: ShapePlacement) => Promise<void>;

export type EditShapeCallback = (selection: Communicator.Selection.SelectionItem, placement: ShapePlacement) => Promise<void>;

export type RemoveShapeCallback = (selection: Communicator.Selection.SelectionItem, placement: ShapePlacement) => Promise<void>;

export type GetSyntheticShapeIdsFn = () => number[];

export type GetNodeInfoFn = (nodeId: number) => { isSynthetic: boolean, channel?: Channel, placement?: ShapePlacement };

export class ChannelContextMenuOperator implements Communicator.Operator.Operator {
    private _ui: Communicator.Ui.Desktop.DesktopUi;

    private _hwv: Communicator.WebViewer;

    private _menuDisplayed: boolean = false;

    public addShapeCb?: AddShapeCallback;
    
    public editShapeCb?: EditShapeCallback;
    
    public removeShapeCb?: RemoveShapeCallback;
    
    public getNodeInfo: GetNodeInfoFn = () => ({ isSynthetic: false });

    public channels: Channel[] = [];
    
    constructor(hwv: Communicator.WebViewer, ui: Communicator.Ui.Desktop.DesktopUi) {
        this._ui = ui;
        this._hwv = hwv;
    }

    async onMouseUp(event: Communicator.Event.MouseInputEvent) {
        if (event.getButton() === Communicator.Button.Right) {
            const contextMenu = this._ui._getContextMenu();

            this._resetContextMenu();

            const selection = await this._hwv.view.pickFromPoint(event.getPosition(), new Communicator.PickConfig(Communicator.SelectionMask.Face));
            const nodeId = selection.getNodeId();

            if (nodeId) {
                const nodeInfo = this.getNodeInfo(nodeId);

                if (nodeInfo.isSynthetic) {
                    this._buildContextMenu(contextMenu, this._getSynthetciShapeMenuItems(selection, nodeInfo.channel, nodeInfo.placement));
                    this._hwv.selectionManager.selectNode(nodeId);
                } else if (!nodeInfo.isSynthetic && nodeInfo.channel) {
                    this._buildContextMenu(contextMenu, this._getShapeMenuItems(selection, nodeInfo.channel, nodeInfo.placement));
                    this._hwv.selectionManager.selectNode(nodeId);
                } else if (selection.isFaceSelection()) {
                    this._buildContextMenu(contextMenu, this._getBaseMenuItems(selection, ));
                }
            }

            event.setHandled(true);
        }
        if (event.getButton() === Communicator.Button.Left && this._menuDisplayed) {
            this._hwv.selectionManager.clear();
            this._resetContextMenu();
        }
    }

    private _resetContextMenu() {
        $('.context-menu-item').remove();
        $('.context-menu').remove();
        this._menuDisplayed = false;
    }

    private _getBaseMenuItems(selection: Communicator.Selection.SelectionItem) {
        const items: ChannelContextMenuItem[] =  [
            { id: 'create-channel-body', title: 'Add body to' },
            {
                id: 'create-channel-body-and-create-channel',
                parent: 'create-channel-body',
                title: 'New channel',
                action: async () => this.addShapeCb?.(selection, undefined, ShapePlacement.Body) ?? Promise.resolve()
            },
        ];

        for (const channel of this.channels) {
            items.push({
                id: `create-channel-body-and-add-to-${channel.id}`,
                parent: 'create-channel-body',
                title: channel.name,
                action: async () => this.addShapeCb?.(selection, channel, ShapePlacement.Body) ?? Promise.resolve()
            });
        }


        return items;
    }

    private _getSynthetciShapeMenuItems(selection: Communicator.Selection.SelectionItem, channel?: Channel, placement: ShapePlacement = ShapePlacement.Body) {
        const menuItems =  [
            { id: 'edit-part', title: `Edit ${placement === ShapePlacement.Body ? 'body' : 'baffle'}`, action: async () => this.editShapeCb?.(selection, placement) ?? Promise.resolve() },
            { id: 'remove-part', title: `Remove ${placement === ShapePlacement.Body  ? 'body': 'baffle'}`, action: async () => this.removeShapeCb?.(selection, placement) ?? Promise.resolve() }
        ];

        if (placement === ShapePlacement.Body) {
            menuItems.push(
                { id: 'add-baffle', title: 'Add baffle', action: async () => this.addShapeCb?.(selection, channel, ShapePlacement.Baffle) ?? Promise.resolve() }
            );
        }

        return menuItems;
    }
    
    private _getShapeMenuItems(selection: Communicator.Selection.SelectionItem, channel?: Channel, placement?: ShapePlacement) {  
        const menuItems =  [];

        if (placement === ShapePlacement.Body) {
            menuItems.push(
                { id: 'add-baffle', title: 'Add baffle', action: async () => this.addShapeCb?.(selection, channel, ShapePlacement.Baffle) ?? Promise.resolve() }
            );
        }

        return menuItems;
    } 

    private _buildContextMenu(contextMenu: Communicator.Ui.RightClickContextMenu, menuData: ChannelContextMenuItem[]) {
        const $root = $((contextMenu as any)._menuElement)
        const menuMap: { [key: string]: JQuery<HTMLElement> } = {};

        menuData.forEach(item => {
            const menuItem = $('<div>', { class: 'context-menu-item', text: item.title })
                .on('mouseenter', function () {
                    $(this).children('.context-menu').show();
                })
                .on('mouseleave', function () {
                    $(this).children('.context-menu').hide();
                });

            if (item.action) {
                menuItem.on('click', (event) => {
                    event.stopPropagation(); 
                    item.action!();
                    contextMenu.hide();
                    this._menuDisplayed = false;
                })
            }

            if (item.parent) {
                if (!menuMap[item.parent]) {
                    menuMap[item.parent] = $('<div>', { class: 'context-menu' });
                }
                menuMap[item.parent].append(menuItem);
                menuMap[item.parent].parent().addClass('has-children');
            } else {
                $root.append(menuItem);
            }

            menuMap[item.id] = $('<div>', { class: 'context-menu' });
            menuItem.append(menuMap[item.id]);
            this._menuDisplayed = true;
        });
    }

}