import CoolingProperties from "./cooling-properties";
import JobData, { TreeviewFaceGroup, CategoriesData, ChannelEnd, TreeviewFace, MaterialRootMap, Mold } from "./job-data";
import MoldMaterial from "./mold-material";
import { ExportedItemGeometricalData, ExportedJobGeometricalData } from "../../geometry-exporter/ExportedJobGeometricalData"
import CadInfo from "./cad-info";
import { Channel } from "./channel";
import { ProjectType } from "../project/project-data";

namespace defaults {
    export const density: number = 1.0;
    export const ejectionTemperature: number = 160.0;
    export const initialTemperature: number = 20.0;
    export const specificHeat: number = 1.0;
    export const thermalConductivity: number = 1.0;
}

export interface JobDetailsInterface {
    project: string;
    id: string;
    submissionTimestamp: Date;
    cadInfos: Array<CadInfo>;
    version?: number;
}

export class JobDetails implements JobDetailsInterface {
    readonly project: string;
    readonly projectType: string;
    customSolverSettings?: any | null;
    readonly id: string;
    readonly submissionTimestamp: Date;
    readonly cadInfos: Array<CadInfo>;
    readonly version?: number;
    readonly expectedCoolingTime: number | null;

    constructor(project: string, projectType: string, id: string, cadInfos: Array<CadInfo>, submissionTimestamp: Date, expectedCoolingTime: number | null = null) {
        this.project = project;
        this.projectType = projectType;     
        this.id = id;
        this.cadInfos = cadInfos;
        this.submissionTimestamp = submissionTimestamp;
        this.version = 2;
        this.expectedCoolingTime = expectedCoolingTime;
    }

    static create(jobContext: JobData, solveId: string): JobDetails {
        return new JobDetails(jobContext.Project, jobContext.ProjectType, solveId, jobContext.CadInfos, new Date(), jobContext.expectedCoolingTime);
    }

    static getVersion(details: JobDetailsInterface): number {
        return (details?.version) ? details.version : 1;
    }
}

class MaterialDetails {
    readonly name: string;
    readonly moldGroupId: string;
    readonly initialTemperature: number;
    readonly specificHeat: number;
    readonly density: number;
    readonly thermalConductivity: number;

    constructor(name: string,
        moldGroupId: string | undefined,
        initialTemperature?: number,
        specificHeat?: number,
        density?: number,
        thermalConductivity?: number) {
        this.name = name;
        this.initialTemperature = initialTemperature ?? defaults.initialTemperature;
        this.specificHeat = specificHeat ?? defaults.specificHeat;
        this.density = density ?? defaults.density;
        this.thermalConductivity = thermalConductivity ?? defaults.thermalConductivity;
        this.moldGroupId = moldGroupId ? moldGroupId : '0';
    }

    static create(moldMaterial: MoldMaterial): MaterialDetails {
        return new MaterialDetails(moldMaterial.name, moldMaterial.moldGroupId, moldMaterial.temperature, moldMaterial.specificHeat, moldMaterial.density, moldMaterial.thermalConductivity);
    }

    static createSection(moldMaterial?: MoldMaterial): MaterialDetails[] | undefined {
        return (!moldMaterial)
            ? undefined
            : [MaterialDetails.create(moldMaterial)];
    }
}

export class PartMaterialDetails extends MaterialDetails {
    readonly hint?: string | null
    readonly ejectionTemperature?: number | null;

    constructor(name: string,
        moldGroupId: string | undefined,
        initialTemperature?: number,
        specificHeat?: number,
        density?: number,
        thermalConductivty?: number,
        ejectionTemperature?: number,
        hint?: string) {
        super(name, moldGroupId, initialTemperature, specificHeat, density, thermalConductivty);

        this.ejectionTemperature = ejectionTemperature ?? null;
        this.hint = hint ?? null;
    }

    static create(moldMaterial: MoldMaterial, hint?: string): PartMaterialDetails {
        return new PartMaterialDetails(moldMaterial.name,
            moldMaterial.moldGroupId,
            moldMaterial.temperature,
            moldMaterial.specificHeat,
            moldMaterial.density,
            moldMaterial.thermalConductivity,
            moldMaterial.ejectionTemperature,
            hint);
    }

    static createSection(moldMaterial?: MoldMaterial): PartMaterialDetails[] | undefined {
        if (!moldMaterial) {
            return undefined;
        } else {
            const partMaterial = PartMaterialDetails.create(moldMaterial);

            //Set second material for runners(hardcoded for now)
            const runnerMaterialData = {
                id: "0",
                name: partMaterial.name,
                moldGroupId: partMaterial.moldGroupId,
                temperature: partMaterial.initialTemperature,
                specificHeat: partMaterial.specificHeat,
                density: partMaterial.density,
                thermalConductivity: partMaterial.thermalConductivity
            }
            const runnerMaterial = PartMaterialDetails.create(runnerMaterialData, "runners");

            return [partMaterial, runnerMaterial];
        }
    }
}

export class Materials {
    readonly Molds?: MaterialDetails[];
    readonly Channels?: MaterialDetails[];
    readonly Parts?: PartMaterialDetails[];

    constructor(molds?: MaterialDetails[], channels?: MaterialDetails[], parts?: PartMaterialDetails[]) {
        this.Molds = molds;
        this.Channels = channels;
        this.Parts = parts;
    }

    static create(materials: MaterialRootMap, basicMoldOverride: boolean, projectType: ProjectType): Materials {
        const channelsMaterialsDetails = materials["Channels"].map(m => MaterialDetails.create(m));
        let moldPartMaterialDetails = materials["Molds"].map(m => MaterialDetails.create(m));
        if (basicMoldOverride || projectType == ProjectType.Feasibility) {
            moldPartMaterialDetails = materials["BasicMold"].map(m => MaterialDetails.create(m));
        }
        return new Materials(moldPartMaterialDetails,
            channelsMaterialsDetails,
            PartMaterialDetails.createSection(materials["Parts"][0]));
    }
}

export class Cooling {
    readonly radius: number;
    readonly flow: number;
    readonly fluid: string;
    readonly temperature: number;
    readonly frozenWallThickness: number;

    constructor(radius: number, flow: number, fluid: string, temperature: number, frozenWallThickness: number) {
        this.radius = radius;
        this.flow = flow;
        this.fluid = fluid;
        this.temperature = temperature;
        this.frozenWallThickness = frozenWallThickness;
    }

    static create(properties: CoolingProperties): Cooling {
        return new Cooling(properties.radius, properties.flow, properties.fluid?.name, properties.temperature, properties.frozenWallThickness);
    }
}

export class Categories {
    readonly Materials: Materials;
    readonly Cooling: Cooling;

    constructor(materials: Materials, cooling: Cooling) {
        this.Materials = materials;
        this.Cooling = cooling;
    }

    static create(categories: CategoriesData, basicMoldOverride: boolean, projectType: ProjectType): Categories {
        return new Categories(Materials.create(categories.Materials, basicMoldOverride, projectType),
            Cooling.create(categories.Cooling));
    }
}

export class GeometricalInfo {
    readonly format: string;
    readonly dataUrl: string;

    constructor(format: string, dataUrl: string) {
        this.format = format;
        this.dataUrl = dataUrl;
    }
}

export class PartDetails {
    readonly id?: string;
    readonly exchangeId?: string | null;
    readonly name?: string;
    readonly fullName?: string;
    readonly materialIndex: number;
    readonly GeometricalInfo: GeometricalInfo;
    readonly cadFileName?: string | null;

    constructor(materialIndex: number, geometricalInfo: GeometricalInfo, id?: string, name?: string, fullName?: string, exchangeId?: string | null, cadFileName?: string | null) {
        this.id = id;
        this.exchangeId = exchangeId;
        this.name = name;
        this.fullName = fullName;
        this.materialIndex = materialIndex;
        this.GeometricalInfo = geometricalInfo;
        this.cadFileName = cadFileName;
    }

    static create(item: ExportedItemGeometricalData): PartDetails {
        const geometricalInfo = new GeometricalInfo(item.format, item.dataUrl!);
        return new PartDetails(item.materialIndex, geometricalInfo, item.id, item.name, item.fullName, item.exchangeId, item.cadFileName);
    }

    static createSection(section: ExportedItemGeometricalData[]): PartDetails[] | undefined {
        if (section.length === 0) return undefined;

        let details: PartDetails[] = [];

        for (const item of section) {
            details.push(PartDetails.create(item));
        }

        return details;
    }
}

class FaceDetails {
    readonly id: string;
    readonly exchangeId?: string | null
    readonly name: string;
    readonly fullName: string;
    readonly faceIndex: number;
    readonly GeometricalInfo: GeometricalInfo;

    constructor(geometricalInfo: GeometricalInfo, id: string, name: string, fullName: string, faceIndex: number, exchangeId?: string | null) {
        this.id = id;
        this.exchangeId = exchangeId,
            this.name = name;
        this.fullName = fullName;
        this.faceIndex = faceIndex;
        this.GeometricalInfo = geometricalInfo;
    }

    static create(channelEnd: ChannelEnd, item: ExportedItemGeometricalData): FaceDetails {
        const geometricalInfo = new GeometricalInfo(item.format, item.dataUrl!);
        return new FaceDetails(geometricalInfo, item.id!, item.name!, item.fullName!, channelEnd.face.faceIndex, item.exchangeId);
    }
}

class TreeviewFaceGroupDetails {
    readonly id: string;
    readonly name: string;
    readonly config: TreeviewFace[];
    readonly GeometricalInfo: GeometricalInfo;

    constructor(geometricalInfo: GeometricalInfo, id: string, name: string, config: TreeviewFace[]) {
        this.id = id;
        this.name = name;
        this.GeometricalInfo = geometricalInfo;
        this.config = config;
    }

    static create(baffle: TreeviewFaceGroup, item: ExportedItemGeometricalData): TreeviewFaceGroupDetails {
        const geometricalInfo = new GeometricalInfo(item.format, item.dataUrl!);
        return new TreeviewFaceGroupDetails(geometricalInfo, item.id!, item.name!, baffle.config);
    }
}

export class MoldDetails {
    readonly id?: string;
    readonly name?: string;
    readonly materialIndex: number;
    readonly bodies: PartDetails[] = [];

    constructor(materialIndex: number, id: string, name: string,
        bodies: PartDetails[]) {
        this.id = id;
        this.name = name;
        this.materialIndex = materialIndex;
        this.bodies = bodies;
    }

    static create(moldGroup: Mold, items: ExportedItemGeometricalData[], isBasicMold: boolean, projectType: ProjectType): MoldDetails {
        const filteredItems = (isBasicMold == false && projectType == ProjectType.Design) ? items.filter(item => moldGroup.bodies.some(b => b.path === item.fullName)) : items;

        const bodies = filteredItems.map(item => {
            const geometricalInfo = new GeometricalInfo(item.format, item.dataUrl!);
            return new PartDetails(item.materialIndex, geometricalInfo, item.id, item.name, item.fullName, item.exchangeId, item.cadFileName);
        });
        return new MoldDetails(1, moldGroup.id, moldGroup.name, bodies);
    }

    static createSection(moldGroups: Mold[], geometricalData: ExportedItemGeometricalData[], basicMoldOverride: boolean, projectType: ProjectType): MoldDetails[] | undefined {
        if (basicMoldOverride || projectType == ProjectType.Feasibility) {
            //Here, we assume that there will always be at least one normal group(and user cant delete the only normal mold group left) and we take it as base data for basic mold group
            const firstMoldGroup = [moldGroups[0]];
            return firstMoldGroup.map(moldgroup => {
                return MoldDetails.create(moldgroup, geometricalData, basicMoldOverride, projectType);
            });
        } else {
            if (moldGroups.length === 0 || geometricalData.length === 0) return undefined;

            return moldGroups.filter(moldgroup => moldgroup.bodies.length > 0).map(moldgroup => {
                return MoldDetails.create(moldgroup, geometricalData, false, projectType);
            });
        }
    }
}

export class ChannelDetails {
    readonly id?: string;
    readonly name?: string;
    readonly materialIndex: number;
    readonly bodies: PartDetails[] = [];
    readonly inlets: FaceDetails[] = [];
    readonly outlets: FaceDetails[] = [];
    readonly baffles: (TreeviewFaceGroupDetails|PartDetails)[] = [];

    constructor(materialIndex: number, id: string, name: string,
        bodies: PartDetails[], inlets: FaceDetails[], outlets: FaceDetails[], baffles: (TreeviewFaceGroupDetails|PartDetails)[]) {
        this.id = id;
        this.name = name;
        this.materialIndex = materialIndex;
        this.bodies = bodies;
        this.inlets = inlets;
        this.outlets = outlets;
        this.baffles = baffles;
    }

    static create(channel: Channel, items: ExportedItemGeometricalData[]): ChannelDetails {
        const inlets: FaceDetails[] = [];
        const outlets: FaceDetails[] = [];
        const baffles: (TreeviewFaceGroupDetails|PartDetails)[] = [];
        const bodies = items.filter(i => i.dataUrl.startsWith(`${channel.id}-body`)).map(i => PartDetails.create(i));

        channel.inlets.forEach(inlet => {
            const geometricalData = items.find(item => item.dataUrl === `${inlet.id}.obj`);

            if (geometricalData) {
                inlets.push(FaceDetails.create(inlet, geometricalData));
            }
        });

        channel.outlets.forEach(outlet => {
            const geometricalData = items.find(item => item.dataUrl === `${outlet.id}.obj`);

            if (geometricalData) {
                outlets.push(FaceDetails.create(outlet, geometricalData))
            }
        });

        channel.baffles.forEach(baffle => {
            const geometricalData = items.find(item => item.dataUrl === `${baffle.id}.obj`);

            if (geometricalData ) {
                if (Channel.isTreeviewFaceGroup(baffle)) {
                    baffles.push(TreeviewFaceGroupDetails.create(baffle, geometricalData));
                } else if (Channel.isPart(baffle)) {
                    baffles.push(PartDetails.create(geometricalData));
                }
            }
        });

        return new ChannelDetails(1, channel.id, channel.name, bodies, inlets, outlets, baffles);
    }

    static createSection(channels: Channel[], geometricalData: ExportedItemGeometricalData[]): ChannelDetails[] | undefined {
        if (channels.length === 0 || geometricalData.length === 0) return undefined;

        return channels.filter(channel => channel.bodies.length > 0).map(channel => {
            return ChannelDetails.create(channel, geometricalData);
        });
    }
}

class Model {
    readonly Molds?: MoldDetails[];
    readonly Channels?: ChannelDetails[];
    readonly Parts?: PartDetails[];

    constructor(molds?: MoldDetails[], channels?: ChannelDetails[], parts?: PartDetails[]) {
        this.Molds = molds;
        this.Channels = channels;
        this.Parts = parts;
    }

    static create(categories: CategoriesData, jobGeometricaldata: ExportedJobGeometricalData, basicMoldOverride: boolean, projectType: ProjectType): Model {
        return new Model(MoldDetails.createSection(categories.Mold, jobGeometricaldata.Molds, basicMoldOverride, projectType),
            ChannelDetails.createSection(categories.Channel, jobGeometricaldata.Channels),
            PartDetails.createSection(jobGeometricaldata.Parts));
    }
}

export default class SolverParameters {
    readonly Job: JobDetails;
    readonly Categories: Categories;
    readonly Model: Model;

    constructor(job: JobDetails, categories: Categories, model: Model) {
        this.Job = job;
        this.Categories = categories;
        this.Model = model;
    }

    static create(jobContext: JobData,
        solveId: string,
        geometricalDetails: ExportedJobGeometricalData): SolverParameters {
        return new SolverParameters(
            JobDetails.create(jobContext, solveId),
            Categories.create(jobContext.Categories, jobContext.basicMold.enabled, jobContext.ProjectType as ProjectType),
            Model.create(jobContext.Categories, geometricalDetails, jobContext.basicMold.enabled, jobContext.ProjectType as ProjectType));
    }
}