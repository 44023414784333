import axios from "axios";
import { Channel } from "../store/job/channel";
import { ExportedItemGeometricalData } from "../geometry-exporter/ExportedJobGeometricalData";
import { Document } from "../geometry-exporter/OBJ/Document";
import { doPartsIntersect } from "../utils/hoops.utils";
import { MoldCategory } from "../store/job/mold-categories";
import { Part, Tree } from "../store/job/job-data";
import { addFaceWhenComplete, Face, Faces } from "../geometry-exporter/OBJ/Face";
import { computeVertice, Vertices } from "../geometry-exporter/OBJ/Vertex";
import { findTreeItemByPath } from "./TreeviewFunctions";

export enum SolidOperationType {
    Subtract,
    Noop
}

export type SolidOperation = { type: SolidOperationType, target: string, tool: string };

export async function extractSolidOperations(channels: Channel[], itemGeomData: ExportedItemGeometricalData[], model: Communicator.Model): Promise<SolidOperation[]> {
    const operations: SolidOperation[] = [];

    const findGeomItem = (path: string, dataUrlPattern: string) => {
        return itemGeomData.find(i => i.fullName === path && i.dataUrl.includes(dataUrlPattern));
    }

    for (const channel of channels) {
        const bodyParts = channel.bodies.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);
        const baffleParts = channel.baffles.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);

        for (const baffle of baffleParts) {
            const baffleGeomItem = findGeomItem(baffle.path, 'baffle');

            if (baffleGeomItem?.dataUrl) {
                for (const body of bodyParts) {
                    const bodyGeomItem = findGeomItem(body.path, 'body');

                    if (bodyGeomItem?.dataUrl && await doPartsIntersect(baffle, body, model)) {
                        operations.push({ type: SolidOperationType.Subtract, target: baffleGeomItem.dataUrl, tool: bodyGeomItem.dataUrl });
                    }
                }
            }
        }

        const syntheticParts = [...channel.bodies, ...channel.baffles].filter(Channel.isSyntheticPart).filter(p => p.nodesIds.length === 1); //TODO: check all channel bodies for intersection if needed
        
        for (const sp of syntheticParts) {
            const spGeomItem = findGeomItem(sp.path, 'body') || findGeomItem(sp.path, 'baffle');
            
            if (!spGeomItem?.dataUrl) {
                continue;
            }

            operations.push({ type: SolidOperationType.Noop, target: spGeomItem.dataUrl, tool: spGeomItem.dataUrl });
        }

    }

    return operations;
}

export async function updateGeometricalData(solidOperations: SolidOperation[], channelGeomInfo: ExportedItemGeometricalData[], tree: Tree, model: Communicator.Model): Promise<ExportedItemGeometricalData[]> {
    let updatedChannelGeomInfo: ExportedItemGeometricalData[] = [
        ...channelGeomInfo
    ];
    const dataUrlsToUpdate = new Set<string>([
        ...solidOperations.map(o => o.target),
        ...solidOperations.map(o => o.tool)
    ]);

    for (const dataUrl of dataUrlsToUpdate.values()) {
        const geomInfo = channelGeomInfo.find(i => i.dataUrl === dataUrl);

        if (!geomInfo || !geomInfo.fullName) {
            continue;
        }

        const treeItem = findTreeItemByPath(geomInfo.fullName, tree);

        if (treeItem && geomInfo.id && geomInfo.name && geomInfo.data.size === 0)  {
            const objGeomInfo = await exportSolidGeometricalData(geomInfo.category, geomInfo.prefix, { id: geomInfo.id, name: geomInfo.name, nodesIds: treeItem.nodeIds }, model);
            
            updatedChannelGeomInfo = updatedChannelGeomInfo.filter(gi => gi !== geomInfo);
            updatedChannelGeomInfo.push(objGeomInfo);
        }
    }

    return updatedChannelGeomInfo;
}

export async function process(projectId: string, jobId: string, containerName: string, operations: SolidOperation[]): Promise<void> {
    await axios.post<string>(`/api/solids/${projectId}/${jobId}/${containerName}/process`, operations);
}

async function exportSolidGeometricalData(category: MoldCategory, prefix: string, part: { id: string, name: string, nodesIds: number[] }, model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    const targetObj = await getNodeObj(part.nodesIds[0], model)

    return new ExportedItemGeometricalData("OBJ", new Blob([targetObj], { type: 'text/plain' }), category, prefix, part.id, part.name);
}

async function getNodeObj(nodeId: number, model: Communicator.Model): Promise<string> {
    const document = new Document();
    const faces = document.Faces();
    const vertices = document.Vertices();

    await exportAllNodeFaces(nodeId, model, vertices, faces);

    return document.toString();
}

async function exportAllNodeFaces(nodeId: number, model: Communicator.Model, vertices: Vertices, faces: Faces) {
    const nodeMeshData = await model.getNodeMeshData(nodeId);
    const itemMatrix = model.getNodeNetMatrix(nodeId);
    const vertexIter = nodeMeshData.faces.iterate();
    let face = new Face();

    while (!vertexIter.done()) {
        face.add(vertices.insert(computeVertice(vertexIter.next(), itemMatrix))!);
        face = addFaceWhenComplete(faces, face);
    }

    faces.add(face);
}