/// <reference path="../@types/hoops/hoops_web_viewer.d.ts"/>
import { ExportedItemGeometricalData, ExportedJobGeometricalData } from "./ExportedJobGeometricalData";
import { Document } from "./OBJ/Document";
import { addFaceWhenComplete, Face, Faces } from "./OBJ/Face";
import { computeVertice, Vertex, Vertices } from "./OBJ/Vertex";
import { Part } from "../store/job/job-data";
import { MoldCategory } from "../store/job/mold-categories";
import JobData, { Mold, TreeviewFaceGroup, ChannelEnd, SyntheticMeshInfo } from "../store/job/job-data";
import { PartType } from "../store/job/ceetron-context";
import { Channel } from "../store/job/channel";
import { doPartsIntersect, getAllDescendantSolidNodesIds } from "../utils/hoops.utils";


async function exportNodeFace(nodeId: number, faceIndex: number, model: Communicator.Model, vertices: Vertices, faces: Faces) {
    const nodeMeshData = await model.getNodeMeshData(nodeId);
    const itemMatrix = model.getNodeNetMatrix(nodeId);
    const element = nodeMeshData.faces.element(faceIndex);
    const vertexIter = element.iterate();
    let face = new Face();

    while (!vertexIter.done()) {
        face.add(vertices.insert(computeVertice(vertexIter.next(), itemMatrix))!);
        face = addFaceWhenComplete(faces, face);
    }

    faces.add(face);
}

async function exportSyntheticFace(config: SyntheticMeshInfo, vertices: Vertices, faces: Faces) {
    let face = new Face()

    for (let i = 0; i < config.vertexes.length - 2; i += 3) {
        const vertex = new Vertex(config.vertexes[i], config.vertexes[i + 1], config.vertexes[i + 2]);

        face.add(vertices.insert(vertex)!);
        face = addFaceWhenComplete(faces, face);
    }

    faces.add(face);
}

async function getNodesToExport(item: Part | Mold, model: Communicator.Model): Promise<number[]> {
    // From the user selection, get the list of nodes for export.
    //
    // The export list differs from the input list for a few reasons:
    // - The input list will not contain exact duplicates, but may contain nodes
    //   on the same branch (e.g. a child body and its parent component). This
    //   results in the same geometry being exported multiple times. The solver
    //   can deal with overlapping copies of geometries in a group, but it is
    //   inefficient and should be avoided.
    // - The solver input geometry must be a list of individual closed bodies.
    //   It is thought that an input file with multiple closed bodies would
    //   cause an error. Therefore, only individual bodies should be exported, not
    //   aggregate (e.g. component). (If this limitation is revisited, it would
    //   be better to export the dominator nodes instead of the leaf nodes to
    //   reduce the number of solver input files.)
    // - Some models contain sheet bodies (not the recommended workflow but
    //   useful to some customers). These are not valid inputs to the
    //   solver. In that case, the parent component is exported, not the
    //   leaf bodies, in the hope that, collectively, these disjointed faces
    //   form a closed solid. For this to work, the parent component must be
    //   selected: if the itemized bodies are selected, they will be exported
    //   individually, not as a single model file.
    //
    // This is our best-effort at generating valid geometry inputs to the solver
    // from the frontend.
    return await getAllDescendantSolidNodesIds(item.nodesIds, model);
}

function buildDocumentHeader(document: Document,
    category: MoldCategory,
    prefix: string,
    nodeId?: Communicator.NodeId,
    name?: string,
    fullName?: string) {
    document.addComment(`Category: ${MoldCategory[category]}`);
    document.addComment(`Item#: ${prefix}`);
    if (nodeId) document.addComment("Node.Id: " + nodeId.toFixed(0));
    if (name) document.addComment("Node.Name: " + name);
    if (fullName) document.addComment("Node.FullName: " + fullName);
}

async function exportItemGeometricalData(category: MoldCategory,
    prefix: string,
    nodeId: Communicator.NodeId,
    model: Communicator.Model,
    name?: string,
    fullName?: string,
    materialIndex = 1,
    cadFileName?: string | null): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();

        let exchangeId = model.getNodeExchangeId(nodeId);
        return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, nodeId.toFixed(0), name, fullName, exchangeId, materialIndex, cadFileName);
    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. category = ${MoldCategory[category]}, fullName = ${fullName ?? ''}, name = ${name ?? ''}, nodeId = ${nodeId.toFixed(0)}`;
        throw message;
    }
}

async function exportChannelEndGeometricalData(category: MoldCategory,
    prefix: string,
    end: ChannelEnd,
    model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();
        const faces = document.Faces();
        const vertices = document.Vertices();

        if (Channel.isSyntheticFace(end.face)) {
            await exportSyntheticFace(end.face.config, vertices, faces);

            buildDocumentHeader(document, category, prefix, undefined, end.name);

            return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, end.id, end.name);
        } else {
            await exportNodeFace(end.face.nodeId, end.face.faceIndex, model, vertices, faces);

            buildDocumentHeader(document, category, prefix, end.face.nodeId, end.name);

            let exchangeId = model.getNodeExchangeId(end.face.nodeId);
            return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, end.id, end.name, end.path, exchangeId);
        }

    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. category = ${MoldCategory[category]}, prefix = ${prefix}`;
        throw message;
    }
}

async function exportGenericRectangularMoldGeometricalData(category: MoldCategory, nodeId: number, materialIndex: number, model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();
        const nodeMeshData = await model.getNodeMeshData(nodeId);
        const itemMatrix = model.getNodeNetMatrix(nodeId);
        const vertexIter = nodeMeshData.faces.iterate();
        const faces = document.Faces();
        const vertices = document.Vertices();
        let face = new Face();

        while (!vertexIter.done()) {
            face.add(vertices.insert(computeVertice(vertexIter.next(), itemMatrix))!);
            face = addFaceWhenComplete(faces, face);
        }

        faces.add(face);

        buildDocumentHeader(document, category, '0', nodeId, category === MoldCategory.Cavity ? PartType.CAVITY : PartType.CORE);

        let exchangeId = model.getNodeExchangeId(nodeId);

        return new ExportedItemGeometricalData("OBJ",
            document.export(),
            category === MoldCategory.Cavity ? MoldCategory.Cavity : MoldCategory.Core,
            '0',
            nodeId.toFixed(0),
            category === MoldCategory.Cavity ? PartType.CAVITY : PartType.CORE,
            'auto-' + (category === MoldCategory.Cavity ? PartType.CAVITY : PartType.CORE),
            exchangeId,
            materialIndex
        );
    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. Generic rectangular mold.`;
        throw message;
    }
}

async function exportTreeviewFaceGroupGeometricalData
    (category: MoldCategory, prefix: string, faceGroup: TreeviewFaceGroup, model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();
        const faces = document.Faces();
        const vertices = document.Vertices();

        for (const displayGroup of faceGroup.config) {
            if (Channel.isSyntheticFace(displayGroup)) {
                await exportSyntheticFace(displayGroup.config, vertices, faces);
            } else {
                await exportNodeFace(displayGroup.nodeId, displayGroup.faceIndex, model, vertices, faces);
            }
        }

        buildDocumentHeader(document, category, prefix, 0);

        return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, faceGroup.id, faceGroup.name);
    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. category = ${MoldCategory[category]}, prefix = ${prefix}`;
        throw message;
    }
}

//TODO: add naming prefix
async function exportPartGeometricalData(category: MoldCategory,
    item: Part | Mold,
    model: Communicator.Model,
    prefix: string,
    materialIndex = 1): Promise<ExportedItemGeometricalData[]> {
    const geometricalInfoList: ExportedItemGeometricalData[] = [];

    for (const nodeId of await getNodesToExport(item, model)) {
        const geometricalInfo = await exportItemGeometricalData(category, `${prefix}-${nodeId}`, nodeId, model, item.name, item.path, materialIndex, item.cadFileName);
        geometricalInfoList.push(geometricalInfo);
    }

    return geometricalInfoList;
}

async function exportChannelGeometricalData(category: MoldCategory,
    item: Channel,
    model: Communicator.Model,
    prefix: string) {

    const geometricalInfoList: ExportedItemGeometricalData[] = [];

    const inletPromises = item.inlets.map(async (inlet, inletIndex) => {
        const geometricalInfo = await exportChannelEndGeometricalData(category, `${prefix}-inlet-${inletIndex}`, inlet, model);
        geometricalInfoList.push(geometricalInfo);
    });

    const outletPromises = item.outlets.map(async (outlet, outletIndex) => {
        const geometricalInfo = await exportChannelEndGeometricalData(category, `${prefix}-outlet-${outletIndex}`, outlet, model);
        geometricalInfoList.push(geometricalInfo);
    });

    const faceGroupBaffles = item.baffles.filter(Channel.isTreeviewFaceGroup)
    let bodyIndex = 0;
    let baffleIndex = 0;

    for (const baffle of faceGroupBaffles) {
        const geometricalInfo = await exportTreeviewFaceGroupGeometricalData(category, `${prefix}-baffle-${baffleIndex++}`, baffle, model);
        geometricalInfoList.push(geometricalInfo);
    }

    const faceGroupBodies = item.bodies.filter(Channel.isTreeviewFaceGroup);

    for (const body of faceGroupBodies) {
        const geometricalInfo = await exportTreeviewFaceGroupGeometricalData(category, `${prefix}-body-${bodyIndex++}`, body, model);
        geometricalInfoList.push(geometricalInfo);
    }

    const baffleParts = item.baffles.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);
    const bodyParts = item.bodies.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);

    for (const body of bodyParts) {
        const geometricalInfo = await exportItemGeometricalData(category, `${prefix}-body-${bodyIndex}`, body.nodesIds[0], model, body.name, body.path, undefined, body.cadFileName);

        geometricalInfoList.push(geometricalInfo);
        bodyIndex++;
    }

    for (const baffle of baffleParts) {
        geometricalInfoList.push(await exportItemGeometricalData(category, `${prefix}-body-${bodyIndex}`, baffle.nodesIds[0], model, `body ${bodyIndex}`, baffle.path, undefined, baffle.cadFileName));
        geometricalInfoList.push(await exportItemGeometricalData(category, `${prefix}-baffle-${baffleIndex}`, baffle.nodesIds[0], model, `baffle ${baffleIndex}`, baffle.path, undefined, baffle.cadFileName));
        bodyIndex++;
        baffleIndex++;
    }

    await Promise.all([...inletPromises, ...outletPromises]);

    return geometricalInfoList;
}

async function exportMoldGeometricalData(category: MoldCategory,
    item: Mold,
    model: Communicator.Model,
    prefix: string) {
    const geometricalInfoList: ExportedItemGeometricalData[] = [];

    const bodiesPromises = item.bodies.map(async (body, bodyIndex) => {
        const bodyNodesToExport = await getNodesToExport(body, model);
        bodyNodesToExport.forEach(async (nodeId, nodeIndex) => {
            const geometricalInfo = await exportItemGeometricalData(category, `${prefix}-body-${bodyIndex}-node-${nodeIndex}`, nodeId, model, body.name, body.path, undefined, body.cadFileName);
            geometricalInfoList.push(geometricalInfo);
        });
    });

    await Promise.all([...bodiesPromises]);

    return geometricalInfoList;
}

async function exportSectionGeometricalData(category: MoldCategory,
    items: (Part | Channel | Mold)[],
    model: Communicator.Model,
    materialIndex = 1): Promise<ExportedItemGeometricalData[]> {

    return (await Promise.all(items.map(async (i, index) => {
        if (category == MoldCategory.Channel) {
            return await exportChannelGeometricalData(category, i as Channel, model, (i as Channel).id.replace('channel-', ''));
        } else if (category == MoldCategory.Mold) {
            return await exportMoldGeometricalData(category, i as Mold, model, index.toFixed(0))
        } else {
            return await exportPartGeometricalData(category, i, model, index.toFixed(0), materialIndex)
        }
    }))).flat();
}


// async function getSolidOperations(channels: Channel[], itemGeomData: ExportedItemGeometricalData[], model: Communicator.Model): Promise<SolidOperation[]> {
//     const operations: SolidOperation[] = [];

//     const findGeomItem = (path: string, dataUrlPattern: string) => {
//         return itemGeomData.find(i => i.fullName === path && i.dataUrl.includes(dataUrlPattern));
//     }

//     for (const channel of channels) {
//         const bodyParts = channel.bodies.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);
//         const baffleParts = channel.baffles.filter(Channel.isPart).filter(p => p.nodesIds.length === 1);

//         for (const baffle of baffleParts) {
//             const baffleGeomItem = findGeomItem(baffle.path, 'baffle');

//             if (baffleGeomItem?.dataUrl) {
//                 for (const body of bodyParts) {
//                     const bodyGeomItem = findGeomItem(body.path, 'body');

//                     if (bodyGeomItem?.dataUrl && await doPartsIntersect(baffle, body, model)) {
//                         operations.push({ type: SolidOperationType.Subtract, target: baffleGeomItem.dataUrl, tool: bodyGeomItem.dataUrl });

//                         if (bodyGeomItem.exchangeId) {
//                             const newGeomItem = await exportSolidGeometricalData(MoldCategory.Channel, bodyGeomItem.prefix, body, model);
//                             itemGeomData = itemGeomData.filter(i => i !== bodyGeomItem);
//                             itemGeomData.push(newGeomItem);
//                         }

//                         if (baffleGeomItem.exchangeId) {
//                             const newGeomItem = await exportSolidGeometricalData(MoldCategory.Channel, baffleGeomItem.prefix, baffle, model);
//                             itemGeomData = itemGeomData.filter(i => i !== baffleGeomItem);
//                             itemGeomData.push(newGeomItem);
//                         }
//                     }
//                 }
//             }
//         }

//     }

//     return operations;
// }

export async function exportJobGeometricalData(jobContext: JobData,
    model: Communicator.Model): Promise<ExportedJobGeometricalData> {
    const categories = jobContext.Categories;

    let jobGeometricalData = new ExportedJobGeometricalData();

    if (jobContext.basicMold.enabled) {
        jobGeometricalData.Molds = [
            await exportGenericRectangularMoldGeometricalData(MoldCategory.Cavity, jobContext.basicMold.nodesIds[0], 1, model),
            await exportGenericRectangularMoldGeometricalData(MoldCategory.Core, jobContext.basicMold.nodesIds[1], 1, model)
        ];
    } else {
        jobGeometricalData.Molds = await exportSectionGeometricalData(MoldCategory.Mold, categories.Mold, model, 1);
    }
    jobGeometricalData.Channels = await exportSectionGeometricalData(MoldCategory.Channel, categories.Channel, model, 1);
    jobGeometricalData.Parts = await exportSectionGeometricalData(MoldCategory.Part, categories.Part, model, 1);

    if (categories.Runner && categories.Runner.length > 0)
        jobGeometricalData.Parts = jobGeometricalData.Parts.concat(await exportSectionGeometricalData(MoldCategory.Runner, categories.Runner, model, 2));

    return jobGeometricalData;
}