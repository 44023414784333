import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Status, StatusLabel } from '../../store/job/job-data';
import Grid from '@mui/material/Grid';
import ProjectService from '../../services/ProjectsService';
import { makeStyles } from '@mui/styles';
import SubmitJobButton from './SubmitJobButton';
import { ToggleButton, ToggleButtonGroup, useTheme,Backdrop, CircularProgress } from '@mui/material';
import JobService from '../../services/JobService';
import JobContext from '../../store/job/job-context';
import { JobProjectSwitcherProps } from './JobProjectSwitcherProps';
import queryErrorHandler from '../../react-query/queryErrorHandler';
import { ErrorCancellingJob } from '../../utils/ErrorMessages';
import { useTranslation } from 'react-i18next';

export default function JobProjectSwitcher(props: JobProjectSwitcherProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const jobService = new JobService();
    const jobContext = useContext(JobContext);
    const [solveStatus, setSolveStatus] = useState<number>(Status.None);
    const [startPolling, setStartPolling] = useState<boolean>(false);
    const { container: containerNameParam } = useParams();
    
    const { onJobSubmit } = props;

    const theme = useTheme();
    const classes = makeStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            marginBottom: '1em',
            borderRadius: '2em'
        },
        item: {
            marginRight: '-0.8em',
            marginTop: '0.5em',
            marginBottom: '0.5em'
        },
        setupButton: {
            borderTopRightRadius: "0 !important",
            borderBottomRightRadius: "0 !important",
        },
        button: {
            borderRadius: '1em',
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            paddingLeft: "1em",
            margin: 0,
            textTransform: 'none',
            '&.selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText
            },
            '&.completed': {
                color: theme.palette.success.main
            },
            '&.completed.selected': {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.primary.light
            }
        }
    })();

    const openSetup = async () => {
        if(containerNameParam)
            new ProjectService().navigateToIterationSetup(props.project, props.jobId, containerNameParam, navigate, props.projectType);
    };

    const openResults = async () => {
        if(containerNameParam)
            new ProjectService().navigateToIterationResult(props.project, props.jobId!, containerNameParam, navigate, props.projectType);
    }

    const updateJobStatus = async () => {
        if (props.jobId) {
            const response = await jobService.getJobStatus(props.project, props.jobId, containerNameParam!);
            const status = response !== undefined ? response : Status.None
            setSolveStatus(status);
            jobContext.setStatus(status);
            
            return status;
        } else {
            return null;
        }
    }

    const startPollingJobStatus = () => {
        //If already true dont modify state
        if (startPolling == true) return;

        setStartPolling(true);
    }

    useEffect(() => {
        if (props.job)
            startPollingJobStatus();
    }, [props.project, props.job]);

    useEffect(() => {
        if (startPolling == false) return;

        let timeoutId: NodeJS.Timeout;
        let isUnmount = false;

        async function onTimer() {
            if(isUnmount) return;
            
            const status = await updateJobStatus();

            if (status === Status.InProgress || status === Status.Queued || status === Status.Dispatched || status === Status.Submitted) {
                timeoutId = setTimeout(onTimer, 10000)
            } else {
                setStartPolling(false);
            }
        }

        if (props.job)
            timeoutId = setTimeout(onTimer, 0);

        return () => {
            timeoutId && clearTimeout(timeoutId);
            isUnmount = true;
        }
    }, [startPolling])

    const onJobSubmitted = () => {
        props.backdropFunc ? props.backdropFunc(false) : null;
        startPollingJobStatus();
        onJobSubmit();
    }

    const onJobCancel = async () => {
        if(props.project && props.jobId){
            try{
                await JobService.cancelSubmittedJob(props.project, props.jobId, containerNameParam!)
                props.onJobCancel()
            }catch(error){
                queryErrorHandler(new Error(ErrorCancellingJob));

                setStartPolling(true);
            }
        }
    }

    return (
        <>
            <Grid container className={classes.container}>
                <ToggleButtonGroup
                    id="job-view-switcher"
                    color="primary"
                    value={props.selected}
                    exclusive
                    onChange={(e, newSselected) => { props.onSelection && props.onSelection(newSselected) }}
                >
                    <ToggleButton 
                        className={`${classes.button} ${classes.setupButton} ${props.selected === 'setup' ? 'selected' : ''}` }
                        onClick={() => openSetup()} 
                        value="setup" 
                        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                    >
                       {t("Setup")}
                    </ToggleButton>
                    {jobContext.Status === Status.Completed &&
                        <ToggleButton className={`${classes.button} completed ${props.selected === 'results' ? 'selected' : ''}`}
                            onClick={() => openResults()} value="results">
                             {t("Results")}
                        </ToggleButton>
                    }
                    {(jobContext.Status !== Status.Completed) &&
                        <SubmitJobButton
                            projectId={props.project}
                            jobId={props.jobId!}
                            className={classes.button}
                            onClick={() => {
                                setSolveStatus(Status.Submitted);
                                jobContext.setStatus(Status.Submitted);
                                props.backdropFunc ? props.backdropFunc(true) : null;
                            }}
                            onSubmitted={() => {
                                onJobSubmitted();
                            }}
                            onJobCancel={async () => {
                                await onJobCancel();
                            }}
                            Hwv={props.hwv!}
                            label={t("Results") + ": " + t(StatusLabel[jobContext.Status])}
                            selected={props.selected}
                        />
                    }
                </ToggleButtonGroup>
            </Grid>
        </>
    );
}
