import { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import JobContext from "../../../store/job/job-context";
import { addItemToTree, findTreeItemByNodeId, getAnyCadModelTopTreeItem, removeItemFromTree } from "../../../services/TreeviewFunctions";
import { SyntheticShape, SyntheticShapeCylinder } from "../models/synthetic-part";
import { HoopsEntitiesContext, HoopsEntitiesContextType } from "../../../store/job/hoops-entities-context";
import { channelSelectionModeColorDefault } from "../../../store/uiSettings/communicatorColors";
import { placeBaffleOnChannelBody, placeChannelSegmentNearFace } from "../helpers/channel-builder";
import JobData, { Job, Part, SyntheticPart, Tree } from "../../../store/job/job-data";
import { Channel } from "../../../store/job/channel";
import { TreeviewItemModel } from "../../job/TreeviewNodeModel";
import { ShapePlacement } from "../operators/ChannelContextMenuOperator";

function useAddPartToChannel(jobContext: JobData, entitiesContext: HoopsEntitiesContextType, hwv?: Communicator.WebViewer) {
    const [syntheticPart, setSyntheticPart] = useState<{ nodeId: number, parentNodeId: number, treeItem?: TreeviewItemModel, shape: SyntheticShape, placement: ShapePlacement, channel?: Channel }>();
    const viewer = useRef<Communicator.WebViewer>();
    const tree = useRef<Tree>();

    const addPartToChannel = async function (shape: SyntheticShape, referenceItem?: Communicator.Selection.SelectionItem, channel?: Channel, placement: ShapePlacement = ShapePlacement.Body) {
        const referenceNodeId = referenceItem?.getNodeId();
        if (viewer.current) {
            const parentNodeId = (referenceNodeId ? viewer.current.model.getNodeParent(referenceNodeId) : null) ?? viewer.current.model.getAbsoluteRootNode();
            const nodeId = await entitiesContext.registerShape(shape, channelSelectionModeColorDefault);

            if (nodeId) {
                if (referenceItem && placement === ShapePlacement.Body) {
                    await placeChannelSegmentNearFace(viewer.current, nodeId, shape as SyntheticShapeCylinder, referenceItem as Communicator.Selection.FaceSelectionItem);
                }

                if (referenceItem && placement === ShapePlacement.Baffle) { 
                    await placeBaffleOnChannelBody(viewer.current, nodeId, shape as SyntheticShapeCylinder, referenceItem as Communicator.Selection.FaceSelectionItem);
                }
                setSyntheticPart({ nodeId, parentNodeId, shape, channel, placement });
            }
        }
    };

    useEffect(() => {
        if (!syntheticPart?.treeItem && syntheticPart?.nodeId && syntheticPart?.parentNodeId && tree.current) {
            const parent = findTreeItemByNodeId(syntheticPart.parentNodeId, tree.current) || getAnyCadModelTopTreeItem(tree.current);
            const name = syntheticPart.placement === ShapePlacement.Body ? `Body-${Date.now()}` : `Baffle-${Date.now()}`;

            if (!parent) {
                return;
            }
            const treeItem: TreeviewItemModel = {
                nodeIds: [syntheticPart.nodeId],
                name: name,
                isSelected: true,
                isVisible: true,
                path: `${parent.path}${name}/`,
                cadFileName: ''
            }

            jobContext.setTree({ ...tree.current, [treeItem.path]: treeItem });
            setSyntheticPart(part => {
                return part ? { ...part, treeItem } : part;
            });
        } else if (syntheticPart?.treeItem) {
            const part = {
                id: syntheticPart.treeItem.path,
                name: syntheticPart.treeItem.name,
                nodesIds: syntheticPart.treeItem.nodeIds,
                path: syntheticPart.treeItem.path,
                cadFileName: '',
                config: syntheticPart.shape
            } as Part;

            if (syntheticPart.channel) {
                if (syntheticPart.placement === ShapePlacement.Body) {
                    jobContext.addBodyToChannel(syntheticPart.channel.id, part);
                }
                if (syntheticPart.placement === ShapePlacement.Baffle) {
                    jobContext.addChannelBaffle(syntheticPart.channel.id, part);
                }
            } else {
                const channels: Partial<Channel>[] = [{
                    bodies: [{
                        id: syntheticPart.treeItem.path,
                        name: syntheticPart.treeItem.name,
                        nodesIds: syntheticPart.treeItem.nodeIds,
                        path: syntheticPart.treeItem.path,
                        cadFileName: '',
                        config: syntheticPart.shape
                    } as Part],
                }];
                jobContext.addToChannels(channels);
            }

            viewer.current?.selectionManager.selectNode(syntheticPart.nodeId);
        }
    }, [syntheticPart]);

    useEffect(() => {
        tree.current = jobContext.Tree;
    }, [jobContext.Tree]);

    useEffect(() => {
        viewer.current = hwv;
    }, [hwv]);

    return addPartToChannel;
}

function useEditPart(jobContext: JobData, entitiesContext: HoopsEntitiesContextType, hwv?: Communicator.WebViewer) {
    const viewer = useRef<Communicator.WebViewer>();
    const tree = useRef<Tree>();
    const [syntheticPart, setSyntheticPart] = useState<{ id: string, path: string, name: string, shape: SyntheticShape, channel: Channel, placement: ShapePlacement }>();

    const editPart = useCallback(async function (nodeId: number, shape: SyntheticShape) {
        if (!viewer.current || !tree.current) {
            return;
        }
        const part = findTreeItemByNodeId(nodeId, tree.current);
        if (!part) {
            return;
        }

        const transform = (await viewer.current.model.getNodeNetMatrix(nodeId)).toJson();
        entitiesContext.removeShape(nodeId);
        viewer.current.selectionManager.clear();

        const channel = jobContext.Categories.Channel.find(c => [...c.bodies, ...c.baffles].filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId)));
        if (channel) {
            const body = channel?.bodies.filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId));

            if (body) {
                jobContext.removeBodyFromChannel(channel.id, body.id);
                shape.transform = transform;
                setSyntheticPart({ id: part.path, path: part.path, name: part.name, shape, channel, placement: ShapePlacement.Body });
            }

            const baffle = channel?.baffles.filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId));

            if (baffle) {
                jobContext.removeChannelBaffle(channel.id, baffle.id);
                shape.transform = transform;
                setSyntheticPart({ id: part.path, path: part.path, name: part.name, shape, channel, placement: ShapePlacement.Baffle });
            }

        }
    }, [jobContext.Categories.Channel]);

    useEffect(() => {
        if (syntheticPart) {
            const sp: SyntheticPart = {
                id: syntheticPart.id,
                nodesIds: [],
                config: syntheticPart.shape,
                name: syntheticPart.name,
                path: syntheticPart.path,
                cadFileName: ''
            }

            if (syntheticPart.placement === ShapePlacement.Body) {
                jobContext.addBodyToChannel(syntheticPart.channel.id, sp);
            }
            if (syntheticPart.placement === ShapePlacement.Baffle) {
                jobContext.addChannelBaffle(syntheticPart.channel.id, sp);
            }
        }
    }, [syntheticPart]);

    useEffect(() => {
        tree.current = jobContext.Tree;
    }, [jobContext.Tree]);

    useEffect(() => {
        viewer.current = hwv;
    }, [hwv]);

    return editPart;
}

function useRemovePart(jobContext: JobData, entitiesContext: HoopsEntitiesContextType, hwv?: Communicator.WebViewer) {
    const viewer = useRef<Communicator.WebViewer>();
    const tree = useRef<Tree>();
    const removePartFromChannel = useCallback(async (nodeId: number) => {
        if (!viewer.current || !tree.current) {
            return;
        }

        entitiesContext.removeShape(nodeId);
        viewer.current.selectionManager.clear();


        const channel = jobContext.Categories.Channel.find(c => [...c.bodies, ...c.baffles].filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId)));
        
        if (!channel) {
            return
        }
        
        const body = channel?.bodies.filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId));
        if (body) {
            jobContext.removeBodyFromChannel(channel.id, body.id);
        }

        const baffle = channel?.baffles.filter(Channel.isSyntheticPart).find(b => b.nodesIds.includes(nodeId));
        if (baffle) {
            jobContext.removeChannelBaffle(channel.id, baffle.id);
        }
    }, [jobContext.Categories.Channel]);

    useEffect(() => {
        tree.current = jobContext.Tree;
    }, [jobContext.Tree]);

    useEffect(() => {
        viewer.current = hwv;
    }, [hwv]);

    return removePartFromChannel;
}

export default function useSyntheticPartManagement(hwv?: Communicator.WebViewer) {
    const jobContext = useContext(JobContext);
    const entitiesContext = useContext(HoopsEntitiesContext);
    const addPartToChannel = useAddPartToChannel(jobContext, entitiesContext, hwv);
    const editPart = useEditPart(jobContext, entitiesContext, hwv);
    const removePart = useRemovePart(jobContext, entitiesContext, hwv);

    return {
        addPartToChannel,
        editPart,
        removePart
    }

}