import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { JobContextProvider } from './store/job/job-context';
import MainRouter from './router/MainRouter';
import ErrorBoundary from './components/ErrorBoundary';
import AuthenticationService from './auth/AuthenticationService';
import ConfigurationService from './config/ConfigurationService';
import { ConfigContextProvider } from './store/config/ConfigurationContext';
import AuthenticatedWrapper from './auth/AuthenticatedWrapper';
import { ThemeProvider, Theme } from '@mui/material';
import theme from './styles/main-theme';
import GlobalStyles from '@mui/material/GlobalStyles';
import { queryClient } from './react-query/queryClient';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CreditsAccountContextProvider } from './store/creditsAccount/CreditsAccountContext';
import { UiSettingsContextProvider } from './store/uiSettings/UiSettingsContext';
import { ToastsContainers } from './components/ui/toasts/ToastsContainers';
import { AppStateContextProvider } from './store/app/AppStateContext';

type AppProps = {
    authenticationService: AuthenticationService
    configurationService: ConfigurationService
}


const globalStyle = (theme: Theme) => ({
    '::-webkit-scrollbar': {
        width: '5px'
    },
    '::-webkit-scrollbar-track': {
        background: 'transparent'
    },
    '::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: '5px'
    }
})

const App: FC<AppProps> = (props: AppProps) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={globalStyle} />
            <div className="App">
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundary>
                        <BrowserRouter>
                            <AppStateContextProvider>
                                <AuthenticatedWrapper authenticationService={props.authenticationService} configurationService={props.configurationService}>
                                    <ConfigContextProvider configurationService={props.configurationService}>
                                        <UiSettingsContextProvider>
                                            <CreditsAccountContextProvider>
                                                <JobContextProvider>
                                                    <ToastsContainers />
                                                    <MainRouter />
                                                </JobContextProvider>
                                            </CreditsAccountContextProvider>
                                        </UiSettingsContextProvider>
                                    </ConfigContextProvider>
                                </AuthenticatedWrapper>
                            </AppStateContextProvider>
                        </BrowserRouter>
                    </ErrorBoundary>
                    <ReactQueryDevtools position="bottom-right" />
                </QueryClientProvider>
            </div >
        </ThemeProvider>
    );
}

export default App;