import { useCallback, useContext, useEffect, useRef } from "react";
import JobContext from "../../../store/job/job-context";
import { Tree } from "../../../store/job/job-data";
import { findAllTreeItemsByNodeIds, GetSelectedWithExchangeId, getTreeItemsNodeIds } from "../../../services/TreeviewFunctions";
import { Face, SelectionValidationMode, selectNodeIds } from "../../../utils/hoops.utils";
import { HoopsEntitiesContext } from "../../../store/job/hoops-entities-context";

export default function useSelection(hwv?: Communicator.WebViewer) {
    const jobContext = useContext(JobContext);
    const { getShapeForNodeId } = useContext(HoopsEntitiesContext);
    const viewer = useRef<Communicator.WebViewer>();
    const tree = useRef<Tree>();

    const selectionCallback = (selectionEvents: Communicator.Event.NodeSelectionEvent[], removed: boolean) => {
        // This function is used as a callback for the 'selectionArray' event.
        // The callback is invoked whenever the selection in the selection
        // manager of the web viewer changes.
        //
        // The action may originate from a user interaction with the UI
        // (clicking on the viewer, clicking on the model tree), or from a
        // programmatic event.
        //
        // The function is intended for post-processing selection events. It is
        // allowed to alter the original selection. This will trigger the callback
        // to be re-entered.
        if (!viewer.current || !selectionEvents || JSON.stringify(tree.current) == "{}") return;

        // The application currently supports two selection modes:
        // - Selection of whole nodes (3D bodies or ancestor components)
        // - Selection of faces (for channel definition)
        //
        // Different modes require different actions. We could imagine new modes:
        // - Disable all selections
        // - Turn on or off automatic selection of related instances (grouping)
        // - Automatically select adjacent faces
        // - Etc.
        //
        // The active mode is not defined explicitly in the job context (there is
        // jobContext.SelectionMode by it only applies during channels definition).
        // There should be a way to disambiguate between two modes, and to disable
        // automatic smart selection.
        const selectionNodeIds = selectionEvents.map(e => e.getSelection().getNodeId()).filter((id): id is number => id !== null);
        automaticSmartSelection(new Set(selectionNodeIds), viewer.current);

        // Sync internal state with viewer.
        let selectedEntities: Face[] = viewer.current.selectionManager.getResults().map(si => {
            const nodeId = si.getNodeId()!;
            const faceEntity = si.getFaceEntity();

            return {
                nodeId: nodeId,
                faceIndex: faceEntity?.getCadFaceIndex() ?? 0
            }
        });

        const selectedFacesWithExchangeIds = GetSelectedWithExchangeId(selectedEntities, viewer.current);
        const syntheticShapeFaces = selectedEntities.filter(e => getShapeForNodeId(e.nodeId) !== null);
        
        jobContext.setModelSelection([...selectedFacesWithExchangeIds, ...syntheticShapeFaces]);
    };

    const automaticSmartSelection = (selectionEventNodeIds: Set<number>, hwv: Communicator.WebViewer) => {
        if (selectionEventNodeIds.size === 0 || !tree.current) return;

        // Augment the selection. When an instance is selected, automatically
        // expand the selection to all items in the instance group.
        const treeItems = findAllTreeItemsByNodeIds(selectionEventNodeIds, tree.current);
        const affectedNodeIds = getTreeItemsNodeIds(treeItems);

        // Select the nodes. In case of redundant selection, keep the ancestor
        // (dominator). Use the option that produces a shallow tree with the
        // fewest nodes. This also keeps model tree expansion to a minimum. All
        // methods are otherwise semantically equivalent.
        selectNodeIds([...affectedNodeIds], hwv, SelectionValidationMode.Dominators);
    }

    useEffect(() => {
        hwv?.setCallbacks({
            selectionArray: selectionCallback
        });

        return () => {
            hwv?.unsetCallbacks({
                selectionArray: selectionCallback
            });
        }
    }, [hwv, selectionCallback]);

    useEffect(() => {
        viewer.current = hwv;
    }, [hwv]);

    useEffect(() => {
        tree.current = jobContext.Tree
    }, [jobContext.Tree]);
}