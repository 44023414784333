import { createCylinderMeshData, getFaceBoundingBox } from "../../../utils/hoops.utils";
import { DEFAULT_CHANNEL_LENGTH, DEFAULT_CHANNEL_ORIENTATION, DEFAULT_CHANNEL_RADIUS, SyntheticShape, SyntheticShapeCylinder } from "../models/synthetic-part";

export async function createCylindricalChannelSegment(hwv: Communicator.WebViewer, color = Communicator.Color.red(), radius: number = DEFAULT_CHANNEL_RADIUS, length: number = DEFAULT_CHANNEL_LENGTH): Promise<{ nodeId: number, meshId: Communicator.MeshId }> {
    const faces = createCylinderMeshData(radius, length, 32, DEFAULT_CHANNEL_ORIENTATION);

    const meshData = new Communicator.MeshData();

    for (const face of faces) {
        meshData.addFaces(face.positions);
    }
    meshData.setFaceWinding(Communicator.FaceWinding.Clockwise);
    meshData.setBackfacesEnabled(true);

    const meshId = await hwv.model.createMesh(meshData);
    const meshInstanceData = new Communicator.MeshInstanceData(
        meshId,
        new Communicator.Matrix(),
        "texture-cylinder",
        color
    );

    const nodeId = await hwv.model.createMeshInstance(meshInstanceData);

    return { nodeId, meshId };
}

export async function placeChannelSegmentNearFace(hwv: Communicator.WebViewer, nodeId: number, shape: SyntheticShapeCylinder, referenceSelection: Communicator.Selection.FaceSelectionItem) {
    const referenceFaceEntity = referenceSelection.getFaceEntity();
    const referenceNodeId = referenceSelection.getNodeId();
    const position = referenceFaceEntity.getPosition();
    const normal = referenceFaceEntity.getNormal();
    const scaledNormal = normal.copy().scale(shape.diameter / 2);
    const newSegmentPosition = position.copy().add(scaledNormal);
    const segmentOrientation = Communicator.Point3.createFromArray(DEFAULT_CHANNEL_ORIENTATION);
    const faceBB = await getFaceBoundingBox({ nodeId: referenceNodeId, faceIndex: referenceFaceEntity.getCadFaceIndex() }, hwv);
    const longestAxis = getLongestAxis(faceBB);
    const rotationMatrix = rotateNode(segmentOrientation, longestAxis);
    
    const translationMatrix = new Communicator.Matrix();
    translationMatrix.setTranslationComponent(newSegmentPosition.x, newSegmentPosition.y, newSegmentPosition.z);

    const netMatrix = Communicator.Matrix.multiply(rotationMatrix, translationMatrix);
    await hwv.model.setNodeMatrix(nodeId, netMatrix);

    shape.transform = netMatrix.toJson();
}

export async function placeBaffleOnChannelBody(hwv: Communicator.WebViewer, nodeId: number, shape: SyntheticShapeCylinder, referenceSelection: Communicator.Selection.FaceSelectionItem) {
    const referenceFaceEntity = referenceSelection.getFaceEntity();
    const referenceNodeId = referenceSelection.getNodeId();
    const position = referenceFaceEntity.getPosition();
    const normal = referenceFaceEntity.getNormal();
    const scaledNormal = normal.copy().scale(shape.diameter / 2);
    const newSegmentPosition = position.copy().subtract(scaledNormal);

    // Use the face normal as the segment orientation
    const segmentOrientation = Communicator.Point3.createFromArray(DEFAULT_CHANNEL_ORIENTATION);
    const rotationMatrix = rotateNode(segmentOrientation, normal);
    const translationMatrix = new Communicator.Matrix();

    translationMatrix.setTranslationComponent(newSegmentPosition.x, newSegmentPosition.y, newSegmentPosition.z);

    const netMatrix = Communicator.Matrix.multiply(rotationMatrix, translationMatrix);
    await hwv.model.setNodeMatrix(nodeId, netMatrix);

    shape.transform = netMatrix.toJson();
}

function findPerpendicularVector(vector: Communicator.Point3): Communicator.Point3 {
    let basisVector;

    if (Math.abs(vector.x) <= Math.abs(vector.y) && Math.abs(vector.x) <= Math.abs(vector.z)) {
        basisVector = new Communicator.Point3(1, 0, 0);
    } else if (Math.abs(vector.y) <= Math.abs(vector.x) && Math.abs(vector.y) <= Math.abs(vector.z)) {
        basisVector = new Communicator.Point3(0, 1, 0);
    } else {
        basisVector = new Communicator.Point3(0, 0, 1);
    }

    // Compute the cross product to get a perpendicular vector
    const perpendicularVector = Communicator.Point3.cross(vector, basisVector).normalize();
    return perpendicularVector;
}

function getLongestAxis(bb: Communicator.Box): Communicator.Point3 {
    const extents = bb.extents()
    const longestAxis = Math.max(extents.x, extents.y, extents.z);

    if (longestAxis === extents.x) {
        return new Communicator.Point3(1, 0, 0);
    } else if (longestAxis === extents.y) {
        return new Communicator.Point3(0, 1, 0);
    } else {
        return new Communicator.Point3(0, 0, 1);
    }
}

function rotateNode(currentOrienation: Communicator.Point3, targetOrientation: Communicator.Point3): Communicator.Matrix { 
    let rotationAxis = Communicator.Point3.cross(currentOrienation, targetOrientation);
    if (rotationAxis.length() < 1e-6) {
        rotationAxis = findPerpendicularVector(currentOrienation); // Example: x-axis
    } else {
        rotationAxis.normalize();
    }

    const dotProduct = Communicator.Point3.dot(currentOrienation, targetOrientation);
    const rotationAngle = Math.acos(dotProduct) * (180 / Math.PI);
    return  Communicator.Matrix.createFromOffAxisRotation(rotationAxis, rotationAngle);
}


