import react, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Summarize from '@mui/icons-material/Summarize';
import { Help as HelpIcon, GridView as GridViewIcon, Logout, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AuthContext from '../../auth/AuthenticationContext';
import routes from '../../router/routes';
import { Divider, ListItemIcon, useTheme } from '@mui/material';
import CreditsAccountContext, { AccountUsersConfiguration } from '../../store/creditsAccount/CreditsAccountContext';
import Logo from ".//img/logo.png";
import { makeStyles } from '@mui/styles';
import React from 'react';
import NotificationsMenu from './NotificationsMenu';
import HelpMenu from './HelpMenu';
import GroupIcon from '@mui/icons-material/Group';
import { UserRole } from '../users/UserList';
import { CanSeeCredits } from '../../utils/visibilityConfig.utils';
import { useTranslation } from 'react-i18next';
import { UISettingsModal } from '../uisettings/UISettingsModal';
import { useMsal } from '@azure/msal-react';
import { useAppStateGuard } from '../../hooks/useAppStateGuard';

//TODO. Pending to add the search capabilities.
export default function SearchAppBar() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openUISettingsModal, setOpenUISettingsModal] = useState<boolean>(false);
    const checkAppStateGuard = useAppStateGuard();
    const navigate = checkAppStateGuard(useNavigate());
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const creditsAccountContext = useContext(CreditsAccountContext);
    const open = Boolean(anchorEl);
    const { instance } = useMsal();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = makeStyles({
        button: {
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer'
        }
    })();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        handleMenuClose();
        authContext?.logout();
    };

    const homeHandle = () => {
        navigate(routes.root);
    };

    const openProfile = () => {
        navigate(routes.account);
    }

    const openUiSettings = () => {
        setOpenUISettingsModal(true); 
    }

    const openMetrics = () => {
        navigate(routes.metrics);
    }
   
    const isFreeAccount = () => {
        return creditsAccountContext?.isFree;
    }

    const openUserPage = () => {
        navigate(routes.users);
    }
    const { t } = useTranslation();
    return (
        <Box id="topbar" sx={{ backgroundColor: theme.palette.background.paper}}>
            <AppBar position="static" color='transparent' sx={{boxShadow: "none"}}>
                <Toolbar sx={{zIndex: "999"}}>
                    <Box onClick={homeHandle} sx={{ cursor: 'pointer', display: { xs: 'none', sm: 'block' }, color: theme.palette.primary.dark }}>
                        <Box
                            component="img"
                            sx={{ height: 45, }}
                            src={Logo} />
                    </Box>
                    <Box sx={{ marginLeft: '5em', color: theme.palette.info.contrastText }}>
                        <Button id="projects-main-page-menu-item" startIcon={<GridViewIcon />} size="small" aria-label={t("Projects page")} color="inherit"
                            sx={{marginRight: "15px"}}
                            onClick={homeHandle} className={classes.button}>
                                {t('Projects')}
                        </Button>
                        <HelpMenu></HelpMenu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center", marginLeft: 'auto', marginRight: 0 }}>
                        {
                            CanSeeCredits(creditsAccountContext.accountConfiguration.AccountUsersConfiguration, authContext.role) && 
                            <Typography data-testid="creditsAmount" id="user-credits" data-credits={creditsAccountContext?.balance} sx={{color: theme.palette.background.paper, mr: 2}}>{creditsAccountContext?.balance} {t("Credits")} {isFreeAccount() ? t("(Trial)") : ""}</Typography>
                        }

                        {
                            (authContext.role == UserRole.Admin) &&
                            <NotificationsMenu></NotificationsMenu>
                        }

                        <Button
                            data-testid="UserMenu"
                            onClick={handleClick}
                            size="small"
                            sx={{ml: 1, color: theme.palette.background.paper}}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <AccountCircle sx={{marginRight: "5px"}}/>
                            <Typography sx={{textTransform: 'none'}}>{instance.getActiveAccount()?.name}</Typography>
                        </Button>              
                    </Box>
                    
                </Toolbar>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        color: theme.palette.primary.main,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: 5,
                        mr: 5
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {authContext.role == UserRole.Admin && 
                        <div key="myAccountPage">
                            <MenuItem id="companyAccountMenuItem" onClick={openProfile} sx={{paddingLeft: "21px", paddingRight: "25px"}}>
                                <ListItemIcon >
                                    <AccountCircle sx={{color: theme.palette.primary.main}} />
                                </ListItemIcon> 
                                {t("My Account")}
                            </MenuItem>
                            <Divider />
                        </div>
                    }
                    {authContext.role == UserRole.Admin && creditsAccountContext?.isFree == false &&
                        <div key="usersPage">
                            <MenuItem onClick={openUserPage} sx={{paddingLeft: "21px", paddingRight: "25px"}}>
                                <ListItemIcon >
                                    <GroupIcon  sx={{color: theme.palette.primary.main}}/>
                                </ListItemIcon> 
                                {t("Users")}
                            </MenuItem>
                            <Divider />
                        </div>
                    }
                    <MenuItem onClick={openUiSettings} sx={{paddingLeft: "21px", paddingRight: "25px"}}>
                        <ListItemIcon >
                            <Settings sx={{color: theme.palette.primary.main}}/>
                        </ListItemIcon> 
                        {t("Settings")}
                    </MenuItem>
                    <Divider />
                    { authContext.isSupervisor && <MenuItem onClick={openMetrics} sx={{paddingLeft: "21px", paddingRight: "25px"}}>
                        <ListItemIcon >
                            <Summarize sx={{color: theme.palette.primary.main}}/>
                        </ListItemIcon> 
                        {t("Statistics")}
                    </MenuItem> }
                   { authContext.isSupervisor &&  <Divider />}
                    <MenuItem id="logout-button" onClick={logOut} sx={{paddingLeft: "21px", paddingRight: "25px"}}>
                        <ListItemIcon>
                            <Logout sx={{color: theme.palette.primary.main}} />
                        </ListItemIcon>
                        {t("Logout")}
                    </MenuItem>
                </Menu>                                
            </AppBar>
            <UISettingsModal
                open={openUISettingsModal}
                onClose={() => {setOpenUISettingsModal(false)}}
            />
        </Box>
    )
}